import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { Icon } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

import { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { switchLoginStatus } from "../../login/loginStatusSlice";
import {
  login,
  createAccount,
  createAccount247,
  getSessionByEmail,
  isSessionValid,
} from "../../../api/Account";
import { CTAButton } from "../CTAButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  SignupCompleteBox,
  DownloadCompleteJoinDiscordBox,
  WaitlistBox,
} from "./SignupComplete";
import {
  accountWidgetActions,
  changeStageType,
  changeWindowType,
  incrementStageType,
} from "../../login/accountWidgetSlice";
import { AccountWidgetContext } from "./SignupWidgetMain";
import { useSearchParams } from "react-router-dom";

export const NewLoginWidget = ({ bForPurchase = false }) => {
  const dispatch = useDispatch();
  const accountWidgetStatus = useSelector((state) => state.accountWidget);
  const {
    showPwd,
    setShowPwd,
    loginEmail,
    setLoginEmail,
    loginEmailError,
    setLoginEmailError,
    loginPassword,
    setLoginPassword,
    loginPwdError,
    setLoginPwdError,
    loginAlertMessage,
    setLoginAlertMessage,
    promoCode,
    setPromoCode,
  } = useContext(AccountWidgetContext);

  const validateEmail = (email) => {
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'+/=?^_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{1,31}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9]{1,31})+$"
    );

    // Remove whitespace at the start and end of a email
    email = email.trim();

    setLoginEmail(email);

    if (!emailRegex.test(email)) {
      setLoginEmailError("Invalid email address");
      return false;
    }

    setLoginEmailError("");
    return true;
  };

  const validatePassword = (loginPassword) => {
    const passwordRegex = new RegExp(
      "^[a-zA-Z0-9.!@#$%&'*+/=?^_`{|}~-]{8,32}$"
    );

    setLoginPassword(loginPassword);

    // Don't display regex error if players are logging in instead
    if (accountWidgetStatus.value === "login") {
      setLoginPwdError("");
      return true;
    }

    if (!passwordRegex.test(loginPassword)) {
      setLoginPwdError(
        "Password requirements: 8-32 characters [digits, letters, or !@#$&* symbols]"
      );
      return false;
    }

    setLoginPwdError("");
    return true;
  };

  const validateEmailandPassword = () => {
    return validateEmail(loginEmail) && validatePassword(loginPassword);
  };

  const handleCreateAccount = async (e) => {
    if (!validateEmailandPassword()) {
      return;
    }

    const newAccount = {
      email: loginEmail,
      password: loginPassword,
      promocode: promoCode,
    };
    try {
      var response;
      const path = window.location.pathname;
      if (path === "/register-trial-alpha2") {
        response = await createAccount247(newAccount);
      } else {
        response = await createAccount(newAccount);
      }

      if (response === "Email Accepted") {
        setLoginAlertMessage("");
        if (bForPurchase) {
          // Change to the purchase screen after signing up
          dispatch(accountWidgetActions(changeWindowType("purchase")));
        } else {
          dispatch(accountWidgetActions(incrementStageType()));
        }
      } else if (response === "Please Reset Password") {
        setLoginAlertMessage("Email already in use. Please reset password.");
      } else if (response === "Email already in use") {
        // If email was in use, user may have accidentally tried to login instead
        // We just default to login handling
        handleLogin(null);
      } else if (response === "Waitlist") {
        // Waitlisted user
        dispatch(accountWidgetActions(changeStageType(5)));
      } else {
        // Error sending to server
        setLoginAlertMessage("Error creating Account. Please try again later.");
        // console.log(response);
      }
    } catch (err) {
      // setLoginAlertMessage(err);
      setLoginAlertMessage("Error creating Account. Please try again later.");
    }
  };

  const handleLogin = async (e) => {
    // e.preventDefault();
    if (!validateEmailandPassword()) {
      return;
    }

    const account = { email: loginEmail, password: loginPassword };
    try {
      const response = await login(account);
      if (response.status === 200) {
        setLoginAlertMessage("");

        if (bForPurchase) {
          // Change to the purchase screen after signing up
          dispatch(accountWidgetActions(changeWindowType("purchase")));
        } else {
          // Increment stage to display the next screen after signing up
          dispatch(accountWidgetActions(incrementStageType()));
        }
      } else if (response.status === 401) {
        setLoginAlertMessage(
          <Typography>
            Incorrect password? Reset password
            <a href="/recover-password"> here!</a>
          </Typography>
        );
      } else if (response.status === 429) {
        setLoginAlertMessage("Too Many Login Attempts. Please try again later");
      } else {
        // Error sending to server
        setLoginAlertMessage("Error Logging in. Please try again later.");
      }
    } catch (err) {
      // setLoginAlertMessage(err);
      setLoginAlertMessage("Error logging in. Please try again later.");
    }
  };

  const handleButtonClick = async () => {
    if (accountWidgetStatus.value === "registration") {
      await handleCreateAccount();
    } else {
      await handleLogin();
    }
  };

  var handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };

  //=====================================================================================================
  // Promo Code helpers

  // Gets the query params and set the default promo code
  // Run Only Once when the page is first rendered
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let promoQuery = searchParams.get("promo");
    setPromoCode(promoQuery);
  }, []);

  const validatePromoCode = (promoCode) => {
    const maxPromoCodeLength = 20;
    if (promoCode.length > maxPromoCodeLength) {
      return;
    }

    setPromoCode(promoCode);
  };

  //=====================================================================================================
  // Boxes
  var fieldBoxSX = {
    background: "#f6f4ec99",
    input: { color: "#4f4f52" },
    "& fieldset": { border: "none" },
    borderRadius: 2,
    height: 50,
    width: "83%",
    ml: 0,
  };

  const emailField = (
    <TextField
      id="login-email"
      label="Email Address"
      name="login-email"
      value={loginEmail}
      onChange={(e) => validateEmail(e.target.value)}
      autoFocus
      className="login-field"
      variant="filled"
      error={loginEmailError != ""}
      helperText={loginEmailError}
      sx={[
        fieldBoxSX,
        {
          "& input:-internal-autofill-selected": {
            // WebkitBoxShadow: '0 0 0 30px #15171d inset',
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "#ABABAB",
            height: 17,
            borderRadius: 5,
          },
        },
      ]}
      InputLabelProps={{
        style: { color: "#4f4f52" },
      }}
    />
  );

  var passwordField = (
    <TextField
      name="login-password"
      label="Password"
      type={showPwd ? "" : "password"}
      id="login-password"
      variant="filled"
      // helperText="Incorrect entry."
      value={loginPassword}
      onChange={(e) => validatePassword(e.target.value)}
      error={loginPwdError != ""}
      helperText={loginPwdError}
      sx={fieldBoxSX}
      InputLabelProps={{
        style: { color: "#4f4f52" },
      }}
      onKeyUp={handleKeyUp}
    />
  );

  const promoCodeField = (
    <TextField
      id="promo-code"
      label="Promo Code"
      value={promoCode}
      onChange={(e) => validatePromoCode(e.target.value)}
      autoFocus
      className="login-field"
      variant="filled"
      sx={[
        fieldBoxSX,
        {
          "& input:-internal-autofill-selected": {
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "#ABABAB",
            height: 17,
            borderRadius: 5,
          },
        },
      ]}
      InputLabelProps={{
        style: { color: "#4f4f52" },
      }}
    />
  );

  const checkBox = (theme) => (
    <FormControlLabel
      className="check-box"
      sx={{
        justifyContent: "left",
        marginBottom: "0px",
        "& .MuiFormControlLabel-label": {
          color: "#f8f6ea",
        },
        [theme.breakpoints.down("sm")]: {
          "& .MuiFormControlLabel-label": {
            fontSize: 13,
            color: "#f8f6ea",
            marginLeft: "-8px",
          },
        },
      }}
      control={
        <Checkbox
          size="large"
          onChange={(e) => setShowPwd(e.target.checked)}
          sx={{
            color: "#ebdfb5",
            "&.Mui-checked": {
              color: "#ebdfb5",
            },
            "& .MuiSvgIcon-root": { fontSize: 28 },
          }}
        />
      }
      label="Show Password"
    />
  );

  const ToggleLogin = (theme) => (
    <Link
      component="button"
      underline="none"
      onClick={() => {
        setLoginAlertMessage("");
        if (accountWidgetStatus.value === "registration") {
          dispatch(accountWidgetActions(changeWindowType("login")));
        } else {
          dispatch(accountWidgetActions(changeWindowType("registration")));
        }
      }}
      sx={{
        color: "#ebdfb5",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px",
        },
      }}
    >
      {accountWidgetStatus.value === "registration"
        ? "Already have an account? Log in"
        : "Don't have an account? Sign up"}
    </Link>
  );

  const loginErrorBox =
    loginAlertMessage != "" ? (
      <Alert
        severity="error"
        sx={{
          width: "80%",
          ml: 4,
          mt: 2,
        }}
      >
        {loginAlertMessage}
      </Alert>
    ) : null;

  //=====================================================================================================
  // Description Texts and toggles
  const descriptionText = () => {
    if (bForPurchase) {
      return (
        <p className="alpha-text">TO PURCHASE, PLEASE SIGN UP FOR AN ACCOUNT</p>
      );
    } else {
      return <p className="alpha-text">SIGN UP FOR THE ALPHA NOW!</p>;
    }
  };

  const titleText = () => {
    if (accountWidgetStatus.value === "registration") {
      return <p className="title">DREAMWORLD</p>;
    } else {
      return <p className="small-title">Login to DreamWorld</p>;
    }
  };

  const ButtonText = () => {
    if (bForPurchase) {
      return "BECOME A FOUNDER";
    } else {
      if (accountWidgetStatus.value === "registration") {
        return "JOIN NOW";
      } else {
        return "LOGIN";
      }
    }
  };

  // Displays the login/account fields
  function LoginFields() {
    const theme = useTheme();
    return (
      <>
        {/* Alpha 2 Description */}
        {descriptionText()}

        {/* Sign up box */}
        <div className="login-form">
          {emailField}
          {passwordField}
          {/* {promoCodeField} */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              padding: "0px 10px",
              height: "40px",
            }}
          >
            {checkBox(theme)}
            {ToggleLogin(theme)}
          </Box>
        </div>

        {/* CTA BUTTON */}
        <CTAButton
          CTAButtonText={ButtonText()}
          className="CTA-instance"
          link="https://discord.gg/7MUByhwEub"
          newTab={false}
          disabled={
            loginEmailError != "" ||
            loginPwdError != "" ||
            loginEmail.length == 0 ||
            loginPassword.length == 0
          }
          onClick={handleButtonClick}
          sx={{ marginTop: "20px" }}
        />

        {/* Error message if server returned an error */}
        {loginErrorBox}
      </>
    );
  }

  function getBoxByStage() {
    switch (accountWidgetStatus.stage) {
      case 0:
        return LoginFields();
      case 1:
        return SignupCompleteBox(dispatch);
      case 2:
        return DownloadCompleteJoinDiscordBox();
      case 5:
        return WaitlistBox();
    }
  }

  //=====================================================================================================
  // Final Return
  return (
    <div className="dreamworld-sign-up">
      {/* Title Text */}
      <div className="title-text">
        {titleText()}
        <p className="description">
          THE INFINITE SANDBOX MMO. FOR BUILDERS, BY BUILDERS.
        </p>
      </div>
      {getBoxByStage()}
    </div>
  );
};
