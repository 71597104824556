import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import {
	Divider,
	Icon,
	MenuList,
	makeStyles,
	useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import { showFounders, websiteSecondaryColor } from "../../theme";
import { IconButton, Tooltip } from "@mui/material";

import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { ListItemIcon, ListItemText } from "@mui/material";
import { red } from "@mui/material/colors";

const CenterMenuItem = styled(MenuItem)(({ theme }) => ({
	justifyContent: "center",
	color: "#FFFFFF",
}));

const handleHomeClick = () => {
	window.open("/", "_self");
};
const handleAboutClick = () => {
	window.open("/about", "_self");
};
const handleUpdatesClick = () => {
	window.open("/community", "_self");
};
const handleContactClick = () => {
	window.open("/contact", "_self");
};
const handleFoundersClick = () => {
	window.open("/purchase", "_self");
};

// Prints an icon that shows you the current user when hovered
// Differentiates between mobile and desktop version
const currentUserIcon = (bMobile = false) => {
	let email = Cookies.get("DWsessionEmail");

	if (email === undefined) {
		return;
	}

	let redirect = window.location.pathname;
	const logout = () => {
		Cookies.remove("DWsessionEmail");
		Cookies.remove("DWsession");

		window.open(redirect, "_self");
	};

	if (bMobile) {
		return (
			<>
				<Divider></Divider>
				<CenterMenuItem
				// disabled={false}
				// style={{ "&.disabled": { opacity: "1" } }}
				>
					<ListItemIcon>
						<AccountCircleRoundedIcon></AccountCircleRoundedIcon>
					</ListItemIcon>
					{email}
				</CenterMenuItem>
				<CenterMenuItem onClick={logout}>Log Out</CenterMenuItem>
			</>
		);
	}

	let display = (
		<>
			<p>Logged in as: {email}</p>
			<p>
				Not you?{" "}
				<a onClick={logout} href={redirect}>
					Log out.
				</a>
			</p>
		</>
	);

	return (
		<Tooltip title={display}>
			<AccountCircleRoundedIcon
				style={{
					position: "absolute",
					width: "45px",
					height: "45px",
					right: "50px",
					top: "35px",
					justifySelf: "center",
					alignSelf: "center",
				}}
			></AccountCircleRoundedIcon>
		</Tooltip>
	);
};

// Mobile Headers Can't fix all menu Items
// So we use a dropdown menu instead
const MobileHeader = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const handleClick = (event) => {
		setAnchorEl(document.getElementById("menu-box"));
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className="top-nav-full">
			{/* Mobile Menu Box */}
			<Box
				id="menu-box"
				sx={{
					height: "100%",
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<IconButton
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
					sx={{
						right: "20px",
						alignSelf: "center",
						color: "white",
						transform: "scale(1.4)",
					}}
				>
					{open ? <CloseIcon /> : <MenuIcon />}
				</IconButton>
			</Box>

			{/* // Drop down Menu */}
			<div className="navbar">
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					slotProps={{
						paper: {
							sx: {
								color: "red",
								width: "100%",
								maxWidth: "100%",
								left: "0px !important",
							},
						},
					}}
					MenuListProps={{
						"aria-labelledby": "basic-button",
						sx: {
							width: anchorEl && anchorEl.offsetWidth,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							backgroundColor: websiteSecondaryColor,
							width: "100%",
						},
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<CenterMenuItem onClick={handleHomeClick}>
						HOME
					</CenterMenuItem>
					<CenterMenuItem onClick={handleUpdatesClick}>
						UPDATES
					</CenterMenuItem>
					<CenterMenuItem onClick={handleAboutClick}>
						FAQ
					</CenterMenuItem>
					<CenterMenuItem onClick={handleContactClick}>
						CONTACT
					</CenterMenuItem>
					{showFounders ? (
						<CenterMenuItem onClick={handleFoundersClick}>
							FOUNDERS
						</CenterMenuItem>
					) : null}
					{currentUserIcon(true)}
				</Menu>
			</div>

			{/*Logo */}
			<img
				className="logo-white"
				alt="Logo"
				src="../assets/img/new_design/GraphicAssets/DWLogoColor.png"
				height={"48px"}
				width={48}
				style={{ top: "10px" }}
			></img>
		</div>
	);
};

// Desktop Header
export const NewHeader = () => {
	const theme = useTheme();
	const medMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	let padding = "6px 12px";
	if (medMatchDownMd) {
		padding = "6px 0px";
	}

	const HeaderButton = styled(Button)({
		boxShadow: "none",
		textTransform: "none",
		padding: padding,
		borderRadius: "12px",
		// border: "1px solid",
		lineHeight: 1.5,
		backgroundColor: "#0063cc00",
		// borderColor: "#0063cc",
		"&:hover": {
			backgroundColor: "#7c818360",
			// borderColor: "#0062cc",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: "#0062cc",
			// borderColor: "#005cbf",
		},
		"&:focus": {
			// boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
		},
	});

	if (medMatchDownMd) {
		return <MobileHeader></MobileHeader>;
	}

	return (
		<div className="top-nav-full">
			<div className="navbar">
				<Stack spacing={0} direction="row">
					<HeaderButton
						className="text-wrapper"
						variant="text"
						href="/"
					>
						HOME
					</HeaderButton>
					<HeaderButton
						className="text-wrapper"
						variant="text"
						href="/community"
					>
						UPDATES
					</HeaderButton>
					<HeaderButton
						className="text-wrapper"
						variant="text"
						href="/about"
					>
						FAQ
					</HeaderButton>
					<HeaderButton
						className="text-wrapper"
						variant="text"
						href="/contact"
					>
						CONTACT
					</HeaderButton>
					{showFounders ? (
						<HeaderButton
							className="text-wrapper"
							variant="text"
							href="/purchase"
						>
							FOUNDERS
						</HeaderButton>
					) : null}

					{/* Uncomment the below to add a Updated Terms of Use Banner to the page */}
					{/* <Button
						href="/terms-of-use"
						sx={{ alignSelf: "center", color: "yellow" }}
					>
						{" "}
						Please read our updated Terms of Use and Community
						Guidelines.
					</Button> */}
				</Stack>
			</div>
			{/*Logo */}
			<Button
				alt="Logo"
				className="logo-white"
				onClick={() => window.open("/", "_self")}
				sx={{
					top: "14px",
					"&.MuiButton-root:hover": { bgcolor: "transparent" },
				}}
			>
				<img
					src="../assets/img/new_design/GraphicAssets/DWLogoColorSmall.png"
					height={78}
					width={80}
				></img>
			</Button>

			{currentUserIcon()}
		</div>
	);
};
