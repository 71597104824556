import Header from '../../components/header/Header';
import {Box} from '@mui/material';

export default function PressPage() {
  return (
    <>
      <Box sx={{background: 'black'}}>
        <Header />
      </Box>
      <h1>Press</h1>
    </>
  );
}
