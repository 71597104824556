import React from "react";
import { CTAButton } from "../widget/CTAButton";

export const StylizedTextBlock = ({ title = "Title", text, CTAButton }) => {
	return (
		<div className="join-the-team">
			<div className="discord-info">
				<div className="section-header">
					<img
						className="header-style-graphic-right"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-left.png"
					></img>
					<div className="SectionHeaderTitle">{title}</div>
					<img
						className="header-style-graphic-left"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-right.png"
					></img>
				</div>

				{text}
				{CTAButton}
			</div>
		</div>
	);
};
