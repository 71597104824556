import * as React from 'react';
import { NewHeader } from '../../components/header/NewHeader';
import { WeeklyUpdatesBlock } from '../../components/new_design/WeeklyUpdatesBlock';
import { LinksBlock } from '../../components/footer/LinksBlock';
import { NewFooter } from '../../components/footer/NewFooter';

export default function CommunityPage() {
  return (
    <>
        <NewHeader></NewHeader>
        {/* <PostGrid /> */}
        <WeeklyUpdatesBlock bShowFull={true}></WeeklyUpdatesBlock>
        <LinksBlock></LinksBlock>
        <NewFooter></NewFooter>
    </>
  );
}
