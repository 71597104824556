import React from "react";

export const CookieTextBlock = () => {
	return (
		<div className="legal-block">
			<div className="text-block">
				<p class="legal-text">
					<b>DreamWorld– Cookie Policy</b>
				</p>
				<p class="legal-text">Effective: June 18, 2021</p>
				<ol class="legal-text">
					<li class="li3">
						<span class="s1"></span>
						<span class="s2">
							“Cookies” Explained and Why They are Used
						</span>
					</li>
					<li class="li3">
						<span class="s1"></span>
						<span class="s2">
							The Categories of Cookies Used by DreamWorld
						</span>
					</li>
					<li class="li3">
						<span class="s1"></span>
						<span class="s2">
							How to Manage Your Cookie Preferences
						</span>
					</li>
					<li class="li3">
						<span class="s1"></span>
						<span class="s2">
							Modifications to this Cookie Policy
						</span>
					</li>
					<li class="li4">
						<span class="s1"></span>
						<span class="s2">How to Contact Us</span>
					</li>
				</ol>
				<p class="legal-text">
					DreamWorld Realities, Inc. (“<b>DreamWorld</b>,” “<b>we</b>
					,” “<b>our</b>,” or “<b>us</b>”) is committed to
					transparency with respect to its data collection and use
					practices. This Cookie Policy (“<b>Cookie Policy</b>”)
					explains how we use cookies and related technologies
					throughout the Site and use of the Services and your related
					choices. Please note that this Cookie Policy is provided as
					a statement about privacy and DreamWorld’s use of cookies
					and similar technologies.
					<span class="Apple-converted-space"> </span>Capitalized
					terms used in this Cookie Policy but not defined herein will
					have the meanings given to them in the{" "}
					<a href="https://www.playdreamworld.com/pages/privacy-policy">
						<span class="s3">DreamWorld Privacy Notice</span>
					</a>
					.
				</p>
				<ol class="legal-text">
					<li class="li6">
						“Cookies” Explained and Why They are Used
					</li>
				</ol>
				<p class="legal-text">
					A cookie is a small piece of data (text file) that a website
					– when visited by a user – asks your browser to store on
					your computer or device in order to remember information
					about you, such as your IP address, device identifier,
					language and/or currency preference or login information.
					You can find more information about cookies at:{" "}
					<a href="http://www.allaboutcookies.org/">
						<span class="s3">www.allaboutcookies.org</span>
					</a>
					<span class="s2"> </span>or{" "}
					<a href="https://www.youronlinechoices.eu/">
						<span class="s3">www.youronlinechoices.eu</span>
					</a>
					.
				</p>
				<p class="legal-text">
					We also use a range of similar technologies that are
					deployed when you visit a website and/or embedded in the web
					pages and/or emails you receive and typically stored on your
					computer, device and/or web browser, including, “web
					beacons” and/or “tracking tags” which are small graphics,
					which may be invisible to you, that have a unique identifier
					and are embedded in the web pages you visit and/or email
					message you receive, and “embedded scripts” which are
					programming codes that are designed to collect information
					about your interactions with the Services, such as the links
					you click on. <span class="Apple-converted-space"> </span>
				</p>
				<p class="legal-text">
					We’ll use the term “cookie” throughout this Cookie Policy to
					cover all of these technologies, but we’ll make sure we
					provide you with clear details about them all so that you
					can make informed choices about your cookie settings.
				</p>
				<p class="legal-text">
					Cookies may be used for many different purposes. For
					example, cookies can be used to show that you have
					previously visited the Site or used a feature of the
					Services and to identify which parts of the Services you
					might be most interested in. Cookies can also improve your
					online experience by storing your preferences during your
					visit to the Site and/or use of the Services.
					<span class="Apple-converted-space"> </span>Cookies can also
					be used for security, fraud detection and authentication
					purposes.
				</p>
				<p class="legal-text">
					<span class="s2">
						<b>Types of Cookies</b>
					</span>
				</p>
				<p class="legal-text">
					<i>Session and Persistent Cookies</i>
				</p>
				<ul class="legal-text">
					<li class="li10">
						<span class="s4"></span>
						<i>Session Cookies</i> - these are cookies which expire
						once you close your web browser; and
					</li>
					<li class="li10">
						<span class="s4"></span>
						<i>Persistent Cookies</i> - these are cookies which stay
						on your device for a set period of time or until you
						delete them.
					</li>
				</ul>
				<p class="legal-text">
					<i>First and Third Party Cookies</i>
				</p>
				<ul class="ul1">
					<li class="li10">
						<span class="s4"></span>
						<i>First-party cookies</i> - these are cookies set by
						the website that you are visiting when you are visiting
						the website, either by us, or by a third party at our
						request;
					</li>
					<li class="li10">
						<span class="s4"></span>
						<i>Third-party cookies</i> - these are cookies that are
						set by a party other than that of the website you are
						visiting. If you visit the Site and/or access or use the
						Services and another party sets a cookie through that
						website, other than at our request, this would be a
						third-party cookie.
					</li>
				</ul>
				<ol class="ol1">
					<li class="li6">
						The Categories of Cookies Used by DreamWorld
					</li>
				</ol>
				<p class="legal-text">
					DreamWorld, or our service providers acting on our behalf,
					uses the types of cookies identified above in the following
					ways:
				</p>
				<p class="legal-text">
					<span class="s5">
						<i>Strictly Necessary</i> –{" "}
					</span>
					These cookies are necessary for the Services to function and
					cannot be switched off in our systems. They are usually only
					set or placed in response to actions made by you which
					amount to a request for Services, such as setting your
					privacy preferences, logging in or filling in forms. You can
					set your browser to block or alert you about these cookies,
					but some parts of the Services may not work. For example,
					these cookies are used to:
				</p>
				<ul class="legal-text">
					<li class="li11">
						<span class="s6"></span>Remember information that you
						fill in when performing certain activities while
						accessing or using the Services.
					</li>
					<li class="li11">
						<span class="s6"></span>Pass information from one page
						to the next, for example when filling out support forms
						or surveys.
					</li>
					<li class="li11">
						<span class="s6"></span>Provide the Services according
						to your preferences such as language and location.
					</li>
					<li class="li11">
						<span class="s6"></span>Read your browser and device
						settings to optimize the display of the Site.
					</li>
					<li class="li11">
						<span class="s6"></span>Locate and identify misuse of
						the Services.
					</li>
					<li class="li11">
						<span class="s6"></span>Load the Site uniformly to
						maintain accessibility.
					</li>
					<li class="li7">
						<span class="s6"></span>Fraud prevention and
						authentication.
					</li>
				</ul>
				<p class="legal-text">
					<span class="s5">
						<i>Performance/Analytics Cookies --</i>
						<b>
							<i> </i>
						</b>
					</span>
					These cookies collect information about how visitors use the
					Services, for instance which pages visitors go to most
					often, and if they get error messages from web pages or in
					interactions with the Services. These cookies collect
					anonymous information on the pages visited. All information
					these cookies collect is aggregated and therefore anonymous.
					It is only used to improve how our Services perform.
					<span class="Apple-converted-space"> </span>Web analytics
					that use cookies to gather data to enhance the performance
					of a website fall into this category.
					<span class="Apple-converted-space"> </span>For example,
					they may be used for testing designs and ensuring a
					consistent look and feel is maintained for the user. This
					category does not include cookies used for
					behavioral/targeted advertising networks.
					<span class="Apple-converted-space"> </span>We use cookies
					and other similar technologies to analyze how the Services
					are accessed, used, or performing.
					<span class="Apple-converted-space"> </span>For example,
					these cookies are used to:
				</p>
				<ul class="ul1">
					<li class="li11">
						<span class="s6"></span>Maintain, operate, and
						continually improve the Services.
					</li>
					<li class="li11">
						<span class="s6"></span>Keep track of the number of
						visitors to the pages within the Site.
					</li>
					<li class="li11">
						<span class="s6"></span>Keep track of the length of time
						that each visitor spends on the pages within the Site
						and/or the features of the Services.
					</li>
					<li class="li11">
						<span class="s6"></span>Determine the order in which a
						visitor visits the various pages within the Site and/or
						features of the Services.
					</li>
					<li class="li11">
						<span class="s6"></span>Determine the website a visitor
						was at before navigating to the Site and the website a
						visitor is going to immediately after leaving the Site
						and/or Platform.
					</li>
					<li class="li11">
						<span class="s6"></span>Identify performance issues with
						the Services (or any component thereof).
					</li>
					<li class="li11">
						<span class="s6"></span>Assess which parts of the
						Services (or any component thereof) need improvement
					</li>
					<li class="li7">
						<span class="s6"></span>Obtain information from our
						email newsletters or other communications we send to
						you, including whether you opened or forwarded a
						newsletter or clicked on any of its content, which tells
						us about our newsletters' effectiveness and helps us
						ensure that we're delivering information that you find
						interesting.
					</li>
				</ul>
				<p class="legal-text">
					Please refer to the privacy policies linked below of the
					third party analytic providers currently utilized through
					the Services:
				</p>
				<ul class="ul1">
					<li class="li12">
						<span class="s7"></span>
						<span class="s1">
							Google Analytics:{" "}
							<a href="https://www.google.com/policies/privacy/partners/">
								<span class="s3">
									www.google.com/policies/privacy/partners/
								</span>
							</a>
						</span>
					</li>
					<li class="li7">
						<span class="s6"></span>Facebook Pixel, Facebook Signal
						&amp; Facebook SDK:{" "}
						<span class="s3">facebook.com/about/privacy</span>
					</li>
				</ul>
				<p class="legal-text">
					<span class="s5">
						<i>Functionality Cookies -- </i>
					</span>
					These cookies allow the website to remember choices you make
					(such as your username, language or the region you are in)
					and provide enhanced, more personal features. These cookies
					can also be used to remember changes you have made to text
					size, fonts and other parts of web pages that you can
					customize. The information these cookies collect may be
					anonymized and they cannot track your browsing activity on
					other websites.<span class="Apple-converted-space"> </span>
					These cookies remember choices you make to improve your
					experience.<span class="Apple-converted-space"> </span>These
					cookies let us operate and provide the Services to you
					according to your preferences. For example, we use these
					cookies to:
				</p>
				<ul class="ul1">
					<li class="li11">
						<span class="s6"></span>Remember information that you
						fill in when performing certain activities in connection
						with your use of the Services.
					</li>
					<li class="li7">
						<span class="s6"></span>Store your preferences such as
						language.
					</li>
				</ul>
				<p class="legal-text">
					<span class="s5">
						<i>Targeting/Advertising Cookies</i> –<b> </b>
					</span>
					These cookies are used to deliver advertisements more
					relevant to you and your interests. They are also used to
					limit the number of times you see an advertisement as well
					as help measure the effectiveness of the advertising
					campaigns. They are usually placed by advertising networks
					with the website operator’s permission. They remember that
					you have visited a website and this information is shared
					with other organizations such as advertisers. Targeting or
					advertising cookies will often be linked to site
					functionality provided by the other organizations.
					<span class="Apple-converted-space"> </span>Storetasker uses
					the third party service providers listed below that use
					these cookies to:
				</p>
				<ul class="ul1">
					<li class="li7">
						<span class="s6"></span>Advertise DreamWorld and the
						Services across the Internet.
					</li>
					<li class="li7">
						<span class="s6"></span>Track the performance and
						efficacy of our advertisement campaigns.
					</li>
					<li class="li7">
						<span class="s6"></span>Tailor our marketing to better
						suit your needs and only display ads that are relevant
						to you.
					</li>
				</ul>
				<p class="legal-text">
					Please refer to the privacy policies linked below of the
					third party advertising services currently utilized through
					the Services:
				</p>
				<ul class="ul1">
					<li class="li3">
						<span class="s7"></span>
						<span class="s1">
							Google AdWords:{" "}
							<a href="https://www.google.com/policies/privacy/partners/">
								<span class="s3">
									www.google.com/policies/privacy/partners/
								</span>
							</a>
						</span>
					</li>
					<li class="li7">
						<span class="s6"></span>Facebook Pixel, Facebook Signal
						&amp; Facebook SDK:{" "}
						<span class="s3">facebook.com/about/privacy</span>
					</li>
				</ul>
				<ol class="ol1">
					<li class="li6">How to Manage Your Cookie Preferences</li>
				</ol>
				<p class="legal-text">
					Where required by applicable law, we will obtain your
					consent prior to setting cookies (except for strictly
					necessary cookies).
					<span class="Apple-converted-space"> </span>Most web and
					mobile device browsers automatically accept cookies, but if
					you prefer, you can change your browser to prevent that or
					to notify you each time a cookie is set.
				</p>
				<p class="legal-text">
					In order to manage your cookie preferences, you may alter
					the cookie settings in your browser settings at any time.
					You may accept all, or certain, cookies. If you do disable
					our cookies in your browser settings, you may find that
					certain features and/or functionality of the Services will
					not work.
				</p>
				<p class="legal-text">
					To learn more about cookies, including how to manage cookies
					and opt-out of cookies being placed on your device, please
					visit{" "}
					<a href="http://www.allaboutcookies.org/manage-cookies/index.html">
						<span class="s3">
							http://www.allaboutcookies.org/manage-cookies/index.html
						</span>
					</a>
					<span class="s3">.</span>
				</p>
				<p class="legal-text">
					While tracking is widespread over the internet, there are
					things you can do to minimize these activities with respect
					to browser cookies.
				</p>
				<p class="legal-text">
					<span class="s2">
						<i>Do Not Track Settings</i>
					</span>
				</p>
				<ul class="ul1">
					<li class="li7">
						<span class="s6"></span>You may turn on the Do Not Track
						(“<i>DNT</i>”) setting in your browser, which will
						enable your browser to send a special signal to
						websites, analytics companies, plug in providers, and
						other web services you encounter while browsing to stop
						tracking your activity. To turn on your DNT signal,
						please visit<span class="Apple-converted-space"> </span>
						<a href="https://allaboutdnt.com/#adjust-settings">
							<span class="s3">
								https://allaboutdnt.com/#adjust-settings
							</span>
						</a>
						. Please note that not all companies will respond to or
						honor DNT signals.
					</li>
					<li class="li7">
						<span class="s6"></span>Note that DreamWorld does not
						process or respond to web browsers' "do not track"
						signals or other similar transmissions that indicate a
						request to disable online tracking of users who use the
						Services.
					</li>
				</ul>
				<p class="legal-text">
					<span class="s2">
						<i>Targeting/Advertising Cookies</i>
					</span>
				</p>
				<ul class="ul1">
					<li class="li7">
						<span class="s6"></span>You can opt out of targeted
						advertising by:
					</li>
					<ul class="ul1">
						<li class="li14">
							<span class="s8"></span>
							<span class="s1">
								Facebook -{" "}
								<a href="https://www.facebook.com/settings/?tab=ads">
									<span class="s3">
										https://www.facebook.com/settings/?tab=ads
									</span>
								</a>
							</span>
						</li>
						<li class="li14">
							<span class="s8"></span>
							<span class="s1">
								Google -{" "}
								<a href="https://www.google.com/settings/ads/anonymous">
									<span class="s3">
										https://www.google.com/settings/ads/anonymous
									</span>
								</a>
							</span>
						</li>
						<li class="li12">
							<span class="s8"></span>
							<span class="s1">
								Bing -{" "}
								<a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
									<span class="s3">
										https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
									</span>
								</a>
							</span>
						</li>
					</ul>
				</ul>
				<p class="legal-text"> </p>
				<ul class="ul1">
					<li class="li7">
						<span class="s6"></span>In addition, some of our third
						party analytics and/or advertising/targeting partners
						may be members of the Network Advertising Initiative ("
						<i>NAI</i>") or Digital Advertising Alliance ("
						<i>DAA</i>") Self-Regulatory Program for Online
						Behavioral Advertising. NAI and DAA each provide
						information regarding procedures for opting-out of
						targeted online advertising from participating
						companies.
					</li>
					<ul class="ul1">
						<li class="li11">
							<span class="s9"></span>For information from NAI
							about opting out, click{" "}
							<a href="http://optout.networkadvertising.org/?c=1">
								<span class="s3">here</span>
							</a>
							.
						</li>
						<li class="li11">
							<span class="s9"></span>For information from DAA
							about opting out, click{" "}
							<a href="http://www.aboutads.info/consumers">
								<span class="s3">here</span>
							</a>{" "}
							or visit:{" "}
							<a href="http://optout.aboutads.info/">
								<span class="s3">
									http://optout.aboutads.info/
								</span>
							</a>
							.
						</li>
						<li class="li7">
							<span class="s9"></span>For information about DAA’s
							AppChoices application, click{" "}
							<a href="https://youradchoices.com/appchoices">
								<span class="s3">here</span>
							</a>
							.
						</li>
					</ul>
				</ul>
				<p class="legal-text">
					<span class="s2">
						<i>Google Analytics Opt-Out</i>
					</span>
				</p>
				<ul class="ul1">
					<li class="li17">
						<span class="s6"></span>You can download the Google
						Analytics opt-out browser add-on at:{" "}
						<a href="https://tools.google.com/dlpage/gaoptout/">
							<span class="s3">
								https://tools.google.com/dlpage/gaoptout/
							</span>
						</a>
						.
					</li>
				</ul>
				<ol class="ol1">
					<li class="li6">Modifications to this Policy</li>
				</ol>
				<p class="legal-text">
					DreamWorld reserves the right to amend this Cookie Policy at
					any time and without notice.
					<span class="Apple-converted-space"> </span>The updated and
					amended Cookie Policy will be effective upon posting.
					<span class="Apple-converted-space"> </span>When we make
					material changes to this Cookie Policy, we’ll provide you
					with prominent notice e.g., by displaying a prominent notice
					on the Site.<span class="Apple-converted-space"> </span>If
					you do not agree to the revised Cookie Policy, you should
					alter your preferences, or consider ceasing use of the
					Services. By continuing to access or make use of the
					Services after the changes become effective, you agree to be
					bound by the revised Cookie Policy.
				</p>
				<ol class="ol1">
					<li class="li6">How to Contact Us</li>
				</ol>
				<p class="legal-text">
					If you have any questions about this Cookie Policy, please
					contact us as follows:
				</p>
				<p class="legal-text">
					By email at:<span class="Apple-tab-span"> </span>
					<a href="mailto:support@playdreamworld.com">
						<span class="s3">support@playdreamworld.com</span>
					</a>
					; <i>Subject Line</i>: Privacy Request
				</p>
				<p class="legal-text">
					By mail at: <span class="Apple-tab-span"> </span>
					<span class="Apple-tab-span"> </span>DreamWorld Realities,
					Inc.
				</p>
				<p class="legal-text">
					<span class="Apple-tab-span"> </span>
					<span class="Apple-tab-span"> </span>
					<span class="Apple-tab-span"> </span>
					<span class="Apple-tab-span"> </span>Attn: DreamWorld
					Privacy Request
				</p>
				<p class="legal-text">
					<span>440 N BARRANCA AVE #2952 COVINA, CA 91723</span>
				</p>
			</div>
		</div>
	);
};
