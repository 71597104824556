import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { GenericPictureTitle } from "../../components/header/GenericPictureTitle";
import { LinksBlock } from "../../components/footer/LinksBlock";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";
import { JoinTheTeamBlock } from "../contact_us/JoinTheTeam";
import { AdditionalContacts } from "../contact_us/AdditionalContacts";
import { ContactIntro } from "../contact_us/ContactIntro";
import { CallingCreatorsBlock } from "./CallingCreatorsBlock";

export function ContactPage() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>

			<GenericPictureTitle TitleName={"CONTACT US"}></GenericPictureTitle>
			<ContactIntro></ContactIntro>
			<JoinTheTeamBlock></JoinTheTeamBlock>
			<CallingCreatorsBlock></CallingCreatorsBlock>
			<AdditionalContacts></AdditionalContacts>
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
