import {configureStore} from '@reduxjs/toolkit';
import loginRegistrationToggleReducer from '../components/login/loginRegistrationToggleSlice';
import loginStatusReducer from '../components/login/loginStatusSlice';
import accountWidgetReducer from '../components/login/accountWidgetSlice';

export const store = configureStore({
  reducer: {
    loginRegistrationToggle: loginRegistrationToggleReducer,
    loginStatus: loginStatusReducer,
    accountWidget: accountWidgetReducer,
  },
});
