import React from "react";
import { useState, useEffect } from "react";
import { CTAButton } from "../widget/CTAButton";
import { getAllBlogPosts } from "../../api/BlogPost";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Post from "../../features/community/Post";
import FeaturedPost from "../../features/community/FeaturedPost";
import { CircularProgress, Box, Pagination } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function GetNumCols() {
	const theme = useTheme();
	let cols = 3;
	const lgMatchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
	if (lgMatchDownMd) {
		cols = 2;
	}
	const smallMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	if (smallMatchDownMd) {
		cols = 1;
	}

	return cols;
}

export const WeeklyUpdatesBlock = ({ bShowFull = false }) => {
	const [blogPosts, setBlogPosts] = useState([]);
	const [areBlogPostsLoaded, setAreBlogPostsLoaded] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);

	const theme = useTheme();
	const smallMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	// Set some parameters depending on if we want to show all or just some blog posts
	const numPerPage = bShowFull ? 9 : 3;
	const title = bShowFull ? "All Updates" : "Weekly Development Updates";
	var blogsToShow;

	if (bShowFull) {
		var numPages = Math.ceil((blogPosts.length - 1) / numPerPage);
		var startIndex = 1 + (pageNumber - 1) * numPerPage;
		var lastIndex = startIndex + numPerPage;

		blogsToShow = blogPosts.slice(startIndex, lastIndex); // The featured post shows the first item
	} else {
		blogsToShow = blogPosts.slice(0, numPerPage);
	}

	useEffect(() => {
		let unsubscribed = false;
		const fetchAllBlogPosts = async () => {
			try {
				const res = await getAllBlogPosts();

				if (!unsubscribed) {
					setBlogPosts(res.sort((a, b) => b.Date - a.Date));
					setAreBlogPostsLoaded(true);
				}
			} catch (error) {}
		};
		fetchAllBlogPosts();
		return () => {
			unsubscribed = true;
		};
	}, []);

	var pageChanged = (event, page) => {
		setPageNumber(page);
		console.log("page: " + page);
	};

	const ShowPagination = (
		<Pagination
			count={numPages}
			color="primary"
			size="large"
			siblingCount={smallMatchDownMd ? 0 : 1}
			onChange={pageChanged}
			sx={{ button: { color: "#ffffff" } }}
		/>
	);
	const footer = () => {
		if (!bShowFull) {
			return (
				<CTAButton
					CTAButtonText="VIEW ALL UPDATES"
					className="CTA-instance"
					link="/community"
					newTab={false}
				/>
			);
		} else {
			return ShowPagination;
		}
	};

	return (
		<>
			{bShowFull ? FeaturedPost(blogPosts[0]) : <> </>}

			<div className="weekly-development">
				<div className="discord-info">
					<div className="section-header">
						<img
							className="header-style-graphic-right"
							src="assets/img/new_design/GraphicAssets/Header-style-graphic-left.png"
						></img>
						<div className="SectionHeaderTitle">{title}</div>
						<img
							className="header-style-graphic-left"
							src="assets/img/new_design/GraphicAssets/Header-style-graphic-right.png"
						></img>
					</div>

					<ImageList
						cols={Math.min(blogPosts.length, GetNumCols())}
						gap={20}
					>
						{blogsToShow.map((item) => (
							<ImageListItem key={item.UUID}>
								<Post
									id={item.UUID}
									date={item.Date}
									title={item.Title}
									image={item.ImageURL}
								/>
							</ImageListItem>
						))}
					</ImageList>

					{footer()}
				</div>
			</div>
		</>
	);
};
