import {PaymentElement} from '@stripe/react-stripe-js';
import {useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {Button, Box, Typography, FormControlLabel, Checkbox, Alert} from '@mui/material/';
import logo from '../../images/DreamWorldLogo.png';
import { CTAButton } from '../widget/CTAButton';

import { useSelector, useDispatch } from "react-redux";
import { incrementStageType, accountWidgetActions } from '../login/accountWidgetSlice';


const buttonStyle = {
    alignSelf: 'center',
    marginBottom: '20px',
};

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/purchase_success`,
      },
      redirect: "if_required",
    });

    if (error){
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occured.');
        }
    }

    setIsProcessing(false);

    dispatch(accountWidgetActions(incrementStageType()));
  };

  return (
    <>
        <p className='purchase-text'>
            PAYMENT INFORMATION
        </p>

        <Box
            sx={{width: "80%", marginTop: "20px"}}
            textAlign='center'
        >
            <form id="payment-form" onSubmit={handleSubmit}
             style={{height: '460px', display: 'flex', justifyContent:'space-between', flexDirection: 'column'}}>
                {/* This is the main payment filled out by stripe */}
                <PaymentElement id="payment-element"/>

            
                {stripe && elements && (
                    <CTAButton sx={buttonStyle}
                    CTAButtonText='BECOME A FOUNDER'
                    disabled={isProcessing || !stripe || !elements}
                    type="submit"
                    ></CTAButton>
                
                )}


                {/* Show any error or success messages */}
                {message && (
                <Alert severity="error" sx={{mt: 0}}>
                    {message}
                </Alert>
                )}
            </form>
        </Box>
      
    </>
  );
}
