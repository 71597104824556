import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";
import Button from '@mui/material/Button';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


import {useNavigate} from 'react-router-dom';
import moment from 'moment';

export default function FeaturedPost(blogToShow) {
    var Title = "";
    var Date = "";
    var Src = "";
    var ID = "";

    if(blogToShow != null){
        Title = blogToShow.Title;
        Date = moment(blogToShow.Date).format('MMM DD YYYY');
        Src = blogToShow.ImageURL;
        ID = blogToShow.UUID;
    }

    var onClickFunction = () => {
		window.open("/blogpost/" + ID, "_self");
	};

    console.log(blogToShow)
    return (
        <div className="featured-post" >
            <div className="wrapper">
                <div className="title">
                    UPDATES
                </div>
                <div className = "featured-card">
                    <div className="image-group">
                        <img className="featured-image" src={Src}></img>
                    </div>
                    <div className="featured-text">
                        <div>
                            <div className="header">{Date}</div>
                            <div className="title">{Title}</div>
                        </div>

                        <Button size="large"
                        onClick={onClickFunction}
                        endIcon={<KeyboardDoubleArrowRightIcon />} sx={{
                            // marginTop: "180px",
                            color: "#ffffff",
                            bottom: "0px",
                        }}>
                            Read More
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
