import React, { useState, useEffect, useWindowSize } from "react";
import Confetti from "react-confetti";
const itemsPerPage = 10;
let currentPage = 1;

// Define the cooldown period in milliseconds (e.g., 2 seconds = 2000 ms)
const cooldown = 3000; // 3 seconds cooldown
let lastConfettiTime = 0; // Store the time of the last confetti launch
// Google Sheets API configuration
const spreadsheetId = "1Tw50lZFZZ1kKnsgC-ZgWl3CkmuFoeM62R5bN0newLS4";
const sheetName = "Scoreboard";
const apiKey = "AIzaSyB-_3lx_4qmQig6KSjT6s8dXbBUl6-r8Wg";
const apiEndpoint = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${apiKey}`;

// Popup and overlay elements
var popup = document.getElementById("userPopup");
var overlay = document.querySelector(".overlay");
var lastUpdateElement = document.getElementById("lastUpdate");

var leaderboardData = [];
var leaderboard;
var confetti = false;

function fetchGoogleSheet() {
	// Fetch data from Google Sheets
	fetch(apiEndpoint)
		.then((response) => response.json())
		.then((data) => {
			const rows = data.values;
			if (rows && rows.length > 1) {
				// Get the timestamp from the last column of the first row (assuming it is at index 8)
				const lastUpdateTimestamp = rows[1][9] || "N/A";
				updateLastUpdateTime(lastUpdateTimestamp);

				// Parse the leaderboard data
				rows.slice(1).forEach((row) => {
					leaderboardData.push({
						name: row[0] || "N/A",
						totalPoints: parseInt(row[3]) || 0,
						totalEarned: row[5] || 0,
						totalEntries: row[3] || 0,
						validShares: row[2] || 0,
						remainingPoints: row[6] || 0,
						redeemedPoints: row[4] || 0,
						lastRedemption: row[7] || "N/A",
					});
				});

				// Sort leaderboard by total points in descending order
				leaderboardData.sort((a, b) => b.totalPoints - a.totalPoints);

				renderLeaderboard();
				setupPagination();
				updateButtons();
			} else {
				console.error("No data found in the sheet.");
			}
		})
		.catch((error) => console.error("Error fetching data:", error));
}

// Function to update the last update time
function updateLastUpdateTime(timestamp) {
	lastUpdateElement.textContent = timestamp;
}

function closeAllPopups() {
	document.getElementById("userPopup").classList.remove("show");
	document.querySelector(".overlay").classList.remove("show");
}

function renderLeaderboard() {
	leaderboard.innerHTML = "";

	const start = (currentPage - 1) * itemsPerPage;
	const end = start + itemsPerPage;
	const currentItems = leaderboardData.slice(start, end);

	const leftColumn = document.createElement("div");
	const rightColumn = document.createElement("div");
	leftColumn.classList.add("leaderboard-column");
	rightColumn.classList.add("leaderboard-column");

	currentItems.forEach((item, index) => {
		const rank = start + index + 1;
		const div = document.createElement("div");
		div.classList.add("leaderboard-item");

		let avatarClass = "";
		let rankClass = ""; // Classe para aplicar a animação

		// Define a classe de animação de acordo com a posição
		if (rank === 1) {
			avatarClass = "epic";
			rankClass = "rank-1";
		} else if (rank === 2) {
			avatarClass = "gold";
			rankClass = "rank-2";
		} else if (rank === 3) {
			avatarClass = "silver";
			rankClass = "rank-3";
		} else if (rank === 4) {
			avatarClass = "bronze";
			rankClass = "rank-4";
		} else {
			avatarClass = "wood";
		}

		// Adiciona a classe de rank apenas se não estiver vazia
		if (rankClass) {
			div.classList.add(rankClass);
		}

		div.innerHTML = `
            <div class="avatar-container">
                <div class="avatar ${avatarClass}"></div>
            </div>
            <div class="info">
                <div class="rank-number"><span>${rank}</span></div> 
                <div class="username">${item.name}</div>
                <div class="info-content">
                    <div class="titleM">Points</div>
                    <div class="titleM">Earned</div>
                    <div class="resultsM">${item.totalPoints}</div>
                    <div class="resultsM">${item.totalEarned}</div>
                </div>
            </div>
        `;

		// Define a função globalmente
		window.closeAllPopups = closeAllPopups();

		// Função para abrir o pop-up de FAQ
		window.openFAQPopup = function () {
			const overlay = document.querySelector(".overlay");

			if (overlay) {
				overlay.classList.add("show");
			}
		};

		div.addEventListener("click", () => openPopup(item));

		const container = document.createElement("div");
		container.classList.add("item-container");
		container.appendChild(div);

		if (index < 5) {
			leftColumn.appendChild(container);
		} else {
			rightColumn.appendChild(container);
		}

		setTimeout(() => {
			div.classList.add("fade-in");
		}, index * 100);
	});

	leaderboard.appendChild(leftColumn);
	leaderboard.appendChild(rightColumn);
}

function openPopup(item) {
	document.getElementById("userName").textContent = item.name;
	document.getElementById("totalEntries").textContent = item.totalEntries;
	document.getElementById("validShares").textContent = item.validShares;
	document.getElementById("totalEarned").textContent = item.totalEarned;
	document.getElementById("remainingPoints").textContent =
		item.remainingPoints;
	document.getElementById("lastRedemption").textContent = item.lastRedemption;
	document.getElementById("redeemedPoints").textContent = item.redeemedPoints;

	// Show popup and overlay
	popup.classList.add("show");
	overlay.classList.add("show");
}

function closePopup() {
	popup.classList.remove("show");
	overlay.classList.remove("show");
}

// Função de configuração de paginação
function setupPagination() {
	const totalPages = Math.ceil(leaderboardData.length / itemsPerPage);
	const pageNumbers = document.getElementById("pageNumbers");
	const paginationContainer = document.querySelector(".pagination");

	// Limpa o conteúdo atual da paginação
	pageNumbers.innerHTML = "";

	// Verifica se há mais de uma página
	if (totalPages > 1) {
		paginationContainer.style.display = "flex"; // Mostra a paginação

		for (let i = 1; i <= totalPages; i++) {
			const span = document.createElement("span");
			span.textContent = i;
			span.classList.add("page-number");
			if (i === currentPage) span.classList.add("active");

			span.addEventListener("click", () => {
				currentPage = i;
				renderLeaderboard();
				updatePaginationActiveState();
				updateButtons();
			});

			pageNumbers.appendChild(span);
		}
	} else {
		paginationContainer.style.display = "none"; // Esconde a paginação
	}
}

// Função para atualizar o estado ativo da paginação
function updatePaginationActiveState() {
	const pageNumbers = document.querySelectorAll(".page-number");
	pageNumbers.forEach((page, index) => {
		page.classList.toggle("active", index === currentPage - 1);
	});
}

function updateButtons() {
	const prevButton = document.getElementById("prev");
	const nextButton = document.getElementById("next");

	prevButton.disabled = currentPage === 1;
	nextButton.disabled =
		currentPage === Math.ceil(leaderboardData.length / itemsPerPage);
}

export function RAFPage() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const [isConfetti, setIsConfetti] = useState(false);

	// Function to trigger confetti with cooldown
	function TriggerConfetti(trigger = false) {
		const currentTime = Date.now(); // Get current time in milliseconds
		// const { width, height } = useWindowSize();

		// Check if the cooldown period has passed
		if (trigger && currentTime - lastConfettiTime >= cooldown) {
			lastConfettiTime = currentTime; // Update the last confetti time
			setIsConfetti(true);
		}
		if (!trigger) {
			setIsConfetti(false);
		}
	}

	function RenderConfetti() {
		if (isConfetti) {
			return (
				<Confetti
					width={windowSize.width}
					height={windowSize.height}
					confettinumber={200}
					confettiradius={6}
					confetticolors={[
						"#FF0000",
						"#00FF00",
						"#0000FF",
						"#FFFF00",
						"#FF00FF",
						"#00FFFF",
					]}
				/>
			);
		} else {
			return <></>;
		}
	}

	function initVarsOnRender() {
		// Initialize variables from the rendered page
		leaderboard = document.getElementById("leaderboard");
		popup = document.getElementById("userPopup");
		overlay = document.querySelector(".overlay");
		lastUpdateElement = document.getElementById("lastUpdate");

		fetchGoogleSheet();
		// Após carregar os dados da planilha
		if (leaderboardData.length === 0) {
			leaderboard.innerHTML = "<p class='no-data'>No records found.</p>";
			document.querySelector(".pagination").style.display = "none"; // Esconde a paginação
		} else {
			renderLeaderboard();
			setupPagination();
			updateButtons();
		}

		// Get the avatar element
		const avatar = document.getElementById("ConfettiPop");
		// Add event to trigger confetti with cooldown on mouseenter
		if (avatar) {
			avatar.addEventListener("mouseenter", () => {
				TriggerConfetti(true);
			});
			avatar.addEventListener("mouseleave", () => {
				TriggerConfetti(false);
			});
		}

		document.getElementById("prev").addEventListener("click", () => {
			if (currentPage > 1) {
				currentPage--;
				renderLeaderboard();
				updatePaginationActiveState();
				updateButtons();
			}
		});

		document.getElementById("next").addEventListener("click", () => {
			if (
				currentPage < Math.ceil(leaderboardData.length / itemsPerPage)
			) {
				currentPage++;
				renderLeaderboard();
				updatePaginationActiveState();
				updateButtons();
			}
		});

		// Add event listeners to close the popup
		document.querySelectorAll(".close-btn").forEach((btn) => {
			btn.addEventListener("click", closePopup);
		});

		overlay.addEventListener("click", closePopup);
	}

	// Run only once
	useEffect(() => {
		initVarsOnRender();
		// console.log("running useeffect");
	}, []);

	// Run on any state change
	// useEffect(() => {});

	return (
		<>
			<RenderConfetti></RenderConfetti>
			<div className="leaderboard-container">
				<header className="header">
					<a
						href="https://gleam.io/gvhxH/refer-a-friend"
						target="_blank"
					>
						<button id="ConfettiPop"></button>
					</a>
				</header>

				<section
					id="leaderboard"
					className="leaderboard"
					aria-live="polite"
					aria-label="Leaderboard"
				>
					{/* <!-- Dynamic content will be inserted here --> */}
				</section>
				<div className="last-update">
					<p>
						Last Update: <span id="lastUpdate">Loading...</span>
					</p>
				</div>
				<nav className="pagination" aria-label="Pagination">
					<button id="prev" aria-label="Previous Page" disabled>
						&laquo; Previous
					</button>
					<span
						id="pageNumbers"
						role="navigation"
						aria-label="Page Numbers"
					></span>
					<button id="next" aria-label="Next Page">
						Next &raquo;
					</button>
				</nav>

				{/* <!-- Lua script placeholder for last update date --> */}
			</div>
			<footer className="footer">
				{/* <!-- Link to open the FAQ popup --> */}
				<a href="https://gleam.io/terms" target="_blank">
					Terms of Service
				</a>{" "}
				|
				<a href="https://gleam.io/privacy" target="_blank">
					Privacy Policy
				</a>
				{/* <!-- Credenciais e versão --> */}
				<div className="signature">
					<p>
						DreamWorld Realities © <span id="currentYear"></span>.
						All Rights Reserved.
						<br></br>
						Note: For referral credit, friends must play the game at
						least once in the Steam Playtest. For questions, contact
						Community Managers on
						<a
							href="https://discord.gg/playdreamworld"
							target="_blank"
						>
							Discord
						</a>
						or email
						<a href="mailto:support@playdreamworld.com">
							support@playdreamworld.com
						</a>
						.
					</p>
				</div>
			</footer>
			{/* <!-- User details pop-up --> */}
			<div
				id="userPopup"
				className="popup"
				role="dialog"
				aria-modal="true"
			>
				<div className="popup-content">
					<button className="close-btn" aria-label="Close Popup">
						&times;
					</button>
					<h2 className="popUserName" id="userName">
						User Name
					</h2>
					<hr className="popup-hr8"></hr>
					<ul className="popup-details">
						<li className="titleMPop">
							Total Points:{" "}
							<span className="resultMPop" id="totalEntries">
								0
							</span>
						</li>
						<li className="titleMPop">
							Total Redeemed Points:{" "}
							<span className="resultMPop" id="redeemedPoints">
								0
							</span>
						</li>
						<li className="titleMPop">
							Total Shares:{" "}
							<span className="resultMPop" id="validShares">
								0
							</span>
						</li>
						<li className="titleMPop">
							Total Earned:{" "}
							<span className="resultMPop" id="totalEarned">
								0
							</span>
						</li>
						<hr className="popup-hr"></hr>
						<li className="titleMPop">
							Next Reward:{" "}
							<span className="resultMPop" id="remainingPoints">
								0
							</span>{" "}
							/ 100
						</li>
						<li className="titleMPop">
							Next Payout:{" "}
							<span className="resultMPop" id="lastRedemption">
								N/A
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div className="overlay" onClick={closeAllPopups}></div>
		</>
	);
}
