import React from "react";

export const GenericPictureTitle = ({ TitleName = "Title" }) => {
	var backgroundClass;
	switch (TitleName) {
		case "CONTACT US":
			backgroundClass = "large-mountain";
			break;
		case "ABOUT/FAQ":
			backgroundClass = "mountain-forest";
			break;
		default:
			backgroundClass = "dusk";
	}

	return (
		<div className="title-frame">
			<div className={backgroundClass}></div>
			<p className="title">{TitleName}</p>
		</div>
	);
};
