import Payment from "../../stripe/Payment";

import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
	Button,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
	Alert,
} from "@mui/material/";
import { CTAButton } from "../CTAButton";

function PurchaseSuccess() {
	return (
		<>
			<Box
				sx={{
					width: "80%",
					marginTop: "60px",
					height: "420px",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					alignItems: "center",
				}}
				textAlign="center"
			>
				<p className="purchase-text">Thank you for being a Founder!</p>

				<Typography className="description" sx={{ marginTop: "-20px" }}>
					Your purchase is a fantastic boost in shaping the future of
					DreamWorld. We're also excited to let you know that your
					instant access to DreamWorld Alpha 2 is now unlocked! A
					receipt for your purchase will be sent to your email.
				</Typography>

				<CTAButton CTAButtonText="DOWNLOAD GAME"></CTAButton>
			</Box>
		</>
	);
}

export const NewPurchaseWidget = ({ stage = 0 }) => {
	function widgetSwitch() {
		switch (stage) {
			case 0:
				return <Payment></Payment>;
			case 1:
				return <PurchaseSuccess></PurchaseSuccess>;
			default:
				return <PurchaseSuccess></PurchaseSuccess>;
		}
	}

	return <>{widgetSwitch()}</>;
};
