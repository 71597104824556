import React from "react";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";
import { CTAButton } from "../../components/widget/CTAButton";

export function JoinTheTeamBlock() {
	const text = (
		<>
			{" "}
			<div className="stylized-text-block">
				<p className="text-wrapper">
					As a YCombinator funded company, we’re seeking creative and
					dedicated individuals to join our dynamic team. Explore
					exciting career opportunities on our careers page, where you
					can find information on various roles and submit your
					application. Join us in crafting an extraordinary gaming
					universe!
				</p>
			</div>
		</>
	);

	const button = (
		<CTAButton
			CTAButtonText="VIEW CAREERS"
			link="https://www.ycombinator.com/companies/dreamworld/jobs"
			newTab={true}
		></CTAButton>
	);

	return (
		<>
			<StylizedTextBlock
				title="Join the Dream Team"
				text={text}
				CTAButton={button}
			></StylizedTextBlock>
		</>
	);
}
