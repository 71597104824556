import {useEffect, useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { CTAButton } from '../widget/CTAButton';
import Cookies from 'js-cookie';

function PaymentLoadErrorMessage (){
    return (
		<>
			<Box
				sx={{
					width: "80%",
					marginTop: "60px",
					height: "420px",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					alignItems: "center",
				}}
				textAlign="center"
			>

                <p className="purchase-text">There was an error loading the payment service. Please try again later.</p>
				{/* <Typography className="description" sx={{ marginTop: "-20px" }}>
					There was an error loading the payment service. Please try again later.
				</Typography> */}

			</Box>
		</>
	);
}

function AlreadyPurchasedMessage (){
    return (
		<>
			<Box
				sx={{
					width: "80%",
					marginTop: "60px",
					height: "420px",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					alignItems: "center",
				}}
				textAlign="center"
			>
                <p className="purchase-text">You've already purchased this pack!</p>

				{/* <Typography className="description" sx={{ marginTop: "-20px" }}>
					Thank you for your support!
				</Typography> */}

				<CTAButton CTAButtonText="DOWNLOAD GAME"></CTAButton>
			</Box>
		</>
	);
}

function Payment() {
    // TODO: THESE STATES MAY EVENTUALLY NEED TO BE MOVED OUTSIDE THE WIDGET
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [canPurchase, setCanPurchase] = useState(null);

  useEffect(() => {
    fetch('/api/stripe-config').then(async r => {
      const {publishableKey} = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch('/api/create-payment-intent', {
      method: 'POST',
      body: JSON.stringify({}),
    }).then(async result => {
        var {clientSecret, cost} = await result.json();
        if(result.status == 200){
            console.log("Secret: " + clientSecret)
            console.log("Cost: " + cost)
            setClientSecret(clientSecret);
        }else{
            console.log("error: " + result);
            setClientSecret("");
        }
    });
  }, []);

  // Check Email if purchaseable
  useEffect(() => {
    let email = Cookies.get("DWsessionEmail")
    if(!email){
        setCanPurchase(false);
        return;
    }

    fetch(`/api1/purchaseable/${email}`, {
      method: 'GET',
    }).then(async result => {
        const textResponse = await result.text();
        if(result.status == 200){
            if(textResponse === "Yes"){
                setCanPurchase(true);
            }else{
                setCanPurchase(false);
            }

        }else{
            // console.log("error: " + result);
            setCanPurchase(false)
        }
    });
  }, []);

  const appearance = {
    theme: 'night',
  };
  const options = {
    clientSecret,
    appearance,
  };

  function DetermineBlockToShow(){
    // if(!clientSecret || stripePromise){
    //     return PaymentLoadErrorMessage();
    // }

    if(clientSecret && stripePromise && canPurchase){
        return( 
            <Elements key={clientSecret} stripe={stripePromise} options={options}>
                <CheckoutForm />
            </Elements>
        );
    }

    if(!canPurchase){
        return AlreadyPurchasedMessage();
    }

    return PaymentLoadErrorMessage();

  }

  return (
    <>
      {DetermineBlockToShow()}
    </>
  );
}

export default Payment;
