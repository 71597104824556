import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { LinksBlock } from "../../components/footer/LinksBlock";
import SignupWidgetMain from "../../components/widget/SignupWidget/SignupWidgetMain";

export const RecoveryPage = ({ PageType }) => {
	const page = (
		<div className="recovery-page">
			<div className="overlap-group">{SignupWidgetMain(PageType)}</div>
		</div>
	);

	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>
			{page}
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
};
