import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { getBlogPostById, deleteBlogPostById } from "../../api/BlogPost";
import { deletePostImg } from "../../api/ImageStorage";
import { useConfirm } from "material-ui-confirm";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { LinksBlock } from "../../components/footer/LinksBlock";
import { CTAButton } from "../../components/widget/CTAButton";
import moment from "moment";

export function timestampToDate(timestamp) {
	return moment(timestamp).format("MMM DD YYYY");
}

export default function PostPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const confirm = useConfirm();

	const [blogPost, setBlogPost] = useState({});
	const [isBlogPostLoaded, setIsBlogPostLoaded] = useState(false);

	useEffect(() => {
		let unsubscribed = false;
		const fetchBlogPostById = async (postId) => {
			const res = await getBlogPostById(postId);
			if (!unsubscribed) {
				setBlogPost(res);
				setIsBlogPostLoaded(true);
			}
		};
		fetchBlogPostById(id);
		return () => {
			unsubscribed = true;
		};
	}, [id]);

	const handleDelete = () => {
		confirm({
			description: `This will permanently delete blog post ${blogPost.Title}.`,
		})
			.then(() => handleDeletePost())
			.catch(() => alert("Deletion cancelled."));
	};

	const handleDeletePost = async () => {
		// Delete the image from CDN
		try {
			await deletePostImg(blogPost.ImageURL.split("/").pop());
		} catch (err) {
			alert(err);
		}
		// Delete the post from the DB
		try {
			const response = await deleteBlogPostById(id);
			if (response !== `Blog post ${id} deleted successfully`) {
				alert(response);
				return;
			}
			navigate("/community");
		} catch (err) {
			alert(err);
		}
	};

	const backButton = (
		<CTAButton
			CTAButtonText="VIEW ALL UPDATES"
			className="CTA-instance"
			link="/community"
			newTab={false}
			sx={{ alignSelf: "center" }}
		/>
	);

	const editFunctionality =
		isBlogPostLoaded &&
		sessionStorage.getItem("user") &&
		JSON.parse(sessionStorage.getItem("user")).Role === 5 &&
		Math.floor(Date.now() / 1000) <
			JSON.parse(sessionStorage.getItem("user")).ExpirationTime ? (
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					pb: 10,
					width: "72%",
				}}
			>
				<IconButton
					aria-label="Create"
					size="large"
					sx={{ color: "#8C8D90", p: 0 }}
					onClick={() => navigate(`/editpost/${blogPost.UUID}`)}
				>
					<CreateIcon />
				</IconButton>
				<IconButton
					aria-label="Delete"
					size="large"
					sx={{ color: "#8C8D90", p: 0 }}
					onClick={handleDelete}
				>
					<DeleteIcon />
				</IconButton>
			</Box>
		) : null;

	return (
		<>
			<NewHeader></NewHeader>

			<img
				className="individual-post-image"
				src={blogPost.ImageURL}
			></img>

			<div className="individual-post">
				<div className="text-group">
					<p className="date-text">
						{timestampToDate(blogPost.Date)}
					</p>
					<p className="title-text">{blogPost.Title}</p>
					<img src="../assets/img/new_design/GraphicAssets/Union.png"></img>
					<div className="body-text">
						<MarkdownPreview
							source={blogPost.Content}
							wrapperElement={{ "data-color-mode": "dark" }}
							style={{
								backgroundColor: "#2e2922",
								maxWidth: "100%",
								fontFamily: "Mulish-Medium",
								fontSize: "1.25rem",
								color: "#f8f6ea",
							}}
						></MarkdownPreview>
					</div>
				</div>

				{backButton}
			</div>

			{/* <Box sx={{ backgroundColor: "black", minHeight: "100vh" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					{isBlogPostLoaded ? (
						<MarkdownPreview
							source={blogPost.Content}
							wrapperElement={{ "data-color-mode": "dark" }}
							style={{
								backgroundColor: "black",
								maxWidth: "40%",
							}}
						/>
					) : (
						<CircularProgress sx={{ color: "#8C8D90" }} />
					)}
				</Box>
				{editFunctionality}
			</Box> */}
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
