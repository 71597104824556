import {createSlice} from '@reduxjs/toolkit';

// The slice is registered within the store.js file
const initialState = {value: 'registration'};

const loginRegistrationToggleSlice = createSlice({
  name: 'loginRegistrationToggle',
  initialState,
  reducers: {
    switchLoginRegistrationToggle(state, action) {
        console.log(action);
      if (action.payload === 'login') {
        state.value = 'login';
      } else {
        state.value = 'registration';
      }
    },
  },
});

export const {switchLoginRegistrationToggle} = loginRegistrationToggleSlice.actions;

export default loginRegistrationToggleSlice.reducer;
