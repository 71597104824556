import React from "react";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";

export function ContactIntro() {
	return (
		<div className="contact-intro">
			<p className="text">
				Whether you're in need of assistance, have thoughts to share, or
				interested in joining the team, we're here for you! Please visit
				the appropriate link below for more information.
			</p>
		</div>
	);
}
