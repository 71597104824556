import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { GenericPictureTitle } from "../../components/header/GenericPictureTitle";
import { LinksBlock } from "../../components/footer/LinksBlock";
import { TermsOfUseTextBlock } from "./TermsOfUseText";

export function TermsOfUsePage() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>

			<GenericPictureTitle TitleName="TERMS OF USE"></GenericPictureTitle>
			<TermsOfUseTextBlock></TermsOfUseTextBlock>
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
