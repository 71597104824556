import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { GenericPictureTitle } from "../../components/header/GenericPictureTitle";
import { LinksBlock } from "../../components/footer/LinksBlock";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";
import { QACard } from "../../components/contact-us/QACard";
import { ButtonMailto } from "../contact_us/AdditionalContacts";

const QAs = [
	{
		question: "Who Created DreamWorld?",
		answer: `DreamWorld is brought to life by a passionate and dynamic
        team of 10-15 individuals, each contributing their unique
        talents to craft this revolutionary sandbox MMORPG. From
        visionary game designers and skilled developers to creative
        artists and strategic minds, our team collaborates
        seamlessly to breathe life into the virtual realm. United by
        a shared commitment to innovation and a deep love for
        gaming, DreamWorld's development team is the driving force
        behind the game's immersive world, ensuring that every
        detail is meticulously crafted to provide players with an
        unparalleled and enchanting gaming experience.`,
	},
	{
		question: "What is DreamWorld?",
		answer: "A new open-world MMO building and adventure game.",
	},
	{
		question: "What is Alpha 2?",
		answer: "It is our newest release of DreamWorld, coming in 2024.",
	},
	{
		question: "What features will Alpha 2 have?",
		answer: "Our Alpha 2 release will include revolutionary building features like copy/paste and volumetric selection, in addition to farming, crafting, boss fights, terraforming and more.",
	},
	{
		question:
			"How many bosses are there? Are they all going to be ready to fight in the release?",
		answer: "There will be 5 main bosses, and several of them will be rolled out shortly after release!",
	},
	{
		question: "How big is the map?",
		answer: "DreamWorld is a procedurally infinite world, so it could go on forever. And every player will be contained on that map.",
	},
	{
		question: "Can I play DreamWorld now?",
		answer: "Yes! Sign up on our website www.playdreamworld.com and you can currently join one of our DreamWorld weekly playtests.",
	},
	{
		question: "How much will DreamWorld Alpha 2 cost?",
		answer: "It will be entirely FREE to play.",
	},
	{
		question:
			"What if I already purchased DreamWorld or supported it on Kickstarter?",
		answer: "You will receive a DREAMWORLD FOUNDER PACK when they become available, which will include a variety of cool skins, pets, mounts and more.",
	},
	{
		question: "What platform can I play on?",
		answer: "DreamWorld currently only works on PC, but we are exploring more options in the future.",
	},
	{
		question: "What are the PC requirements to run DreamWorld?",
		answer: (
			<>
				<strong>Min Requirements</strong> (60fps Low): <br />
				Operating System: Windows 7 64 bit <br />
				CPU: Quad Core Intel or AMD processor <br />
				RAM: 8GB <br />
				GPU: GeForce GTX 1060 or Radeon R9 Fury X <br />
				DirectX Version: 12 <br />
				Disk Space: 20 GB <br />
				<br />
				<strong>Recommended Requirements</strong> (60 fps High): <br />
				Operating System: Windows 7 64 bit or Higher <br />
				CPU: Intel i5 7th Gen, AMD Ryzen 5 or Higher <br />
				RAM: 16GB <br />
				GPU: GeForce GTX 2070 or Radeon RX 6600XT <br />
				DirectX Version: 12 <br />
				Disk Space: 20 GB
			</>
		),
	},
	{
		question: "Where can I learn more about DreamWorld?",
		answer: (
			<p>
				The best place to learn is by watching some of our content on{" "}
				<a href="https://www.youtube.com/@PLAYDREAMWORLD">Youtube.</a>
			</p>
		),
	},
	{
		question: "How can I get in contact with the team?",
		answer: (
			<p>
				The best way to join the conversation is on{" "}
				<a href="https://discord.gg/hM2Wb2sqQY">Discord.</a>
				<br></br>
				You can also email us:{" "}
				<ButtonMailto
					label="support@playdreamworld.com"
					mailto="mailto:support@playdreamworld.com"
				></ButtonMailto>
			</p>
		),
	},
];

export function AboutPage() {
	const aboutText = (
		<>
			{" "}
			<div className="stylized-text-block">
				<p className="text-wrapper">
					DreamWorld is brought to life by a passionate and dynamic
					team of 10-15 individuals, each contributing their unique
					talents to craft this revolutionary sandbox MMORPG. From
					visionary game designers and skilled developers to creative
					artists and strategic minds, our team collaborates
					seamlessly to breathe life into the virtual realm. United by
					a shared commitment to innovation and a deep love for
					gaming, DreamWorld's development team is the driving force
					behind the game's immersive world, ensuring that every
					detail is meticulously crafted to provide players with an
					unparalleled and enchanting gaming experience.
				</p>
			</div>
		</>
	);

	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>

			<GenericPictureTitle TitleName={"FAQ"}></GenericPictureTitle>
			{/* <StylizedTextBlock
				title="Who Created DreamWorld?"
				text={aboutText}
			></StylizedTextBlock> */}

			<div className="faq-frame">
				<div className="divider" />
				{QAs.map((item) => (
					<div className="faq-stack">
						<QACard
							question={item.question}
							answer={item.answer}
						></QACard>
					</div>
				))}

				{/* <div className="faq-stack">
					<QACard
						question="This is a question that takes up one line of text."
						answer={"This is an answer that takes up one line."}
					></QACard>
				</div>
				<div className="faq-stack">
					<QACard
						question="This is a longer question that takes up two lines of text. I suppose it's a little longer, but what would it look like on the website? "
						answer={
							"DreamWorld stands out as as sandbox MMO with a primary focus on building, enriched by RPG elements, providing players with a dynamic virtual world to shape and explore"
						}
					></QACard>
				</div> */}
			</div>

			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
