import {Card, CardContent, CardMedia, Typography} from '@mui/material';
import {CardActionArea} from '@mui/material';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Textfit } from 'react-textfit';

import moment from 'moment';

export default function Post(props) {
  const [isHoverOver, setIsHoverOver] = useState(false);

  const navigate = useNavigate();

  const formattedDate = moment(props.date).format('MMM DD YYYY');
  return (
    <Card
      sx={{
        width: 370,
        height: 335,
        backgroundColor: '#27292f00',
        borderRadius: 0,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: '#27292fff',
            zIndex: 1,
            filter: 'brightness(125%)',
        },
      }}
      onMouseEnter={() => setIsHoverOver(true)}
      onMouseLeave={() => setIsHoverOver(false)}
      onClick={() => window.open(`/blogpost/${props.id}`, "_self")}
    >
	  <CardActionArea>
        <CardMedia component="img" height="246" width="432" src={props.image} alt="post image" sx={{
             border: '2px solid #D9C788'
        }} />
        <CardContent>
          <p className='post-block-date'>
            {formattedDate}
          </p>

            <Textfit className='post-block-title' mode="single" forceSingleModeWidth={false}>
                {props.title}
            </Textfit>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
