import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
	position: "relative",
	borderRadius: "500px",
	// height: 100,
	[theme.breakpoints.down("sm")]: {
		width: "100% !important", // Overrides inline-style
		height: 100,
	},
	"&:hover, &.Mui-focusVisible": {
		zIndex: 1,
		"& .backdrop": {
			opacity: 0.55,
		},
		"& .MuiImageMarked-root": {
			opacity: 0,
		},
		// "& .button-text": {
		// 	border: "4px solid currentColor",
		// },
	},
	// Overwriting some responsiveness for small screens
	"@media (max-width: 599.95px)": {
		width: "auto !important",
		height: "auto !important",
	},
}));

const ImageSrc = styled("span")({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: "cover",
	backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.common.white,
}));

// On Press Backdrop
const ImageBackdrop = styled("span")(({ theme }) => ({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: "#ffffff20",
	opacity: 0,
	transition: theme.transitions.create("opacity"),
	borderRadius: "500px",
}));

export const CTAButton = ({
	CTAButtonText = "Button Text",
	className,
	link = "",
	newTab = true,
	disabled = false,
	type,
	sx,
	onClick,
}) => {
	var CTAButtonImg = "../assets/img/new_design/GraphicAssets/CTA-button.png";
	var CTAButtonTextClass = disabled ? `button-text-disabled` : `button-text`;
	var targetText = newTab ? "_blank" : "_self";

	var onClickFunction = () => {
		if (onClick) {
			onClick();
			return;
		}

		// Only go to link if link is populated
		if (link) {
			window.open(link, targetText);
		}
	};

	return (
		<ImageButton
			focusRipple
			className="CTA"
			onClick={onClickFunction}
			disabled={disabled}
			sx={sx}
			type={type}
		>
			<ImageSrc
				className="CTA-button"
				style={{ backgroundImage: `url(${CTAButtonImg})` }}
			/>
			<ImageBackdrop className="backdrop" />
			<Image>
				<div className={CTAButtonTextClass}>{CTAButtonText}</div>
			</Image>
		</ImageButton>
	);
};
