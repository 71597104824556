import React from "react";
import { CTAButton } from "../widget/CTAButton";

export const DiscordBlock = () => {
	return (
		<div className="join-the-discord">
			<div className="discord-info">
				<img
					className="discord-logo"
					alt="Discord logo"
					src="assets/img/new_design/SocialMedia/Discord_logo.svg"
				/>
				<div className="CTA-group">
					<p className="text-wrapper">
						Join the Discord community to connect with developers,
						stay updated on the latest changes, and collaborate with
						fellow builders. Your voice matters in shaping
						DreamWorld!
					</p>
					<CTAButton
						CTAButtonText="JOIN THE DISCORD"
						className="CTA-instance"
						link="https://discord.gg/7MUByhwEub"
						newTab={true}
					/>
				</div>
			</div>
		</div>
	);
};
