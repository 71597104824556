import React from "react";
import { IconButton } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ImageList } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export const LinksBlock = () => {
	const items = [
		{
			src: "../assets/img/new_design/SocialMedia/Discord.png",
			link: "https://discord.gg/pYxZftYzXs",
		},
		{
			src: "../assets/img/new_design/SocialMedia/Youtube.png",
			link: "https://www.youtube.com/@playdreamworld",
		},
		{
			src: "../assets/img/new_design/SocialMedia/TikTok.png",
			link: "https://www.tiktok.com/@playdreamworld",
		},
		{
			src: "../assets/img/new_design/SocialMedia/Twitter.png",
			link: "https://twitter.com/Play_DreamWorld",
		},
		{
			src: "../assets/img/new_design/SocialMedia/Instagram.png",
			link: "https://www.instagram.com/play_dreamworld/",
		},
		{
			src: "../assets/img/new_design/SocialMedia/Facebook.png",
			link: "https://www.facebook.com/PlayDreamWorld2",
		},

		// {
		// 	src: "assets/img/new_design/SocialMedia/Reddit.png",
		// 	link: "https://youtube.com",
		// },
	];

	const theme = createTheme({
		palette: {
			grey: {
				main: "#7c8183FF",
			},
		},
	});

	const theme2 = useTheme();
	const medMatchDownMd = useMediaQuery(theme2.breakpoints.down("md"));

	return (
		<ThemeProvider theme={theme}>
			<div className="social-links-full">
				<div className="small-text1">FOLLOW DREAMWORLD</div>
				<div className="social-links">
					{/* Creates a button with the img src and link that opens in a new tab */}

					<ImageList
						sx={{ width: "100%", height: "fit-content" }}
						cols={medMatchDownMd ? 3 : 6}
						rowHeight={40}
					>
						{items.map((item) => (
							<IconButton
								color="grey"
								className="img-wrapper"
								href={item.link}
								target="_blank"
								key={item.link}
							>
								<img src={item.src}></img>
							</IconButton>
						))}
					</ImageList>
				</div>
			</div>
		</ThemeProvider>
	);
};
