import React from "react";
import { Button, Icon } from "@mui/material";

const svgIcon = (
	<Icon sx={{ width: "100%", height: "100%", marginLeft: "6px" }}>
		<img
			alt="edit"
			src="../assets/img/new_design/GraphicAssets/Stylish-arrow-down.png"
		/>
	</Icon>
);

export const StylishArrowDown = ({ onButtonPress }) => {
	return (
		<div className="stylish-arrow-down">
			<Button
				startIcon={svgIcon}
				sx={{
					width: "100%",
					"&:hover": {
						backgroundColor: "#ffffff1f",
						boxShadow: "none",
					},
					"&& .MuiTouchRipple-child": {
						backgroundColor: "#ffffff38",
					},
				}}
				onClick={onButtonPress}
			></Button>
		</div>
	);
};

// StylishArrowDown.propTypes = {
//     stylishArrowDown: PropTypes.string,
// };
