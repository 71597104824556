import {createSlice} from '@reduxjs/toolkit';

// The slice is registered within the store.js file
const initialState = {
  value:
    sessionStorage.getItem('user') &&
    Math.floor(Date.now() / 1000) < JSON.parse(sessionStorage.getItem('user')).ExpirationTime
      ? true
      : false,
};

const loginStatusSlice = createSlice({
  name: 'loginStatus',
  initialState,
  reducers: {
    switchLoginStatus(state, action) {
      if (action.payload === true) {
        state.value = true;
      } else {
        state.value = false;
      }
    },
  },
});

export const {switchLoginStatus} = loginStatusSlice.actions;

export default loginStatusSlice.reducer;
