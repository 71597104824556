import React from "react";
import { CTAButton } from "../widget/CTAButton";

export const QACard = ({ question = "Title", answer }) => {
	return (
		<div className="faq-question-card">
			<div className="faq-question-text">
				<div className="faq-question">{question}</div>
				<div className="faq-answer">{answer}</div>
			</div>
			<div className="divider" />
		</div>
	);
};
