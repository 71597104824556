import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import {AppBar, Box, Toolbar, Typography, IconButton, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import logo from '../../images/DreamWorldLogo.png';
import PlayNowButton from './PlayNowButton';

const theme = createTheme({
  typography: {
    fontFamily: 'Philosopher',
  },
});

export default function Header() {
  let navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{flexGrow: 1}}>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={
            {
              // background:
              //   "linear-gradient(to right bottom, #35FF6E, #1A86F4, #804AFF, #EB00FF)",
              // background: "linear-gradient(0.25turn, #21a7f1, #804AFF)",
              // background: "transparent",
            }
          }
        >
          <Toolbar>
            <Box
              component="img"
              sx={{
                height: 80,
                width: 80,
                cursor: 'pointer',
              }}
              alt="DreamWorld logo"
              src={logo}
              mr={2}
              mt={2}
            />
            <Typography
              variant="h5"
              component="div"
              sx={{
                flexGrow: 1,
                fontSize: '36px',
                color: 'White',
                cursor: 'pointer',
              }}
              mt={2}
              onClick={() => navigate('/')}
            >
              DREAMWORLD
            </Typography>
            <Box
              display="flex"
              sx={{
                backgroundColor: 'rgba(21,23,29,0.75)',
                borderRadius: '30px',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 0,
              }}
              mt={2}
              p={0.5}
            >
              {isMenuOpen ? (
                <>
                  <IconButton
                    aria-label="close"
                    size="large"
                    sx={{color: 'White', mr: 2}}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Button
                    sx={{color: 'White', fontSize: '20px', mr: 2}}
                    onClick={() => navigate('/')}
                  >
                    HOME
                  </Button>
                  <Button
                    sx={{color: 'White', fontSize: '20px', mr: 2}}
                    onClick={() => navigate('/community')}
                  >
                    COMMUNITY
                  </Button>
                  <Button
                    sx={{color: 'White', fontSize: '20px', mr: 2}}
                    onClick={() => navigate('/about')}
                  >
                    ABOUT
                  </Button>
                  <Button
                    sx={{color: 'White', fontSize: '20px', mr: 2}}
                    onClick={() => navigate('/press')}
                  >
                    PRESS
                  </Button>
                </>
              ) : (
                <IconButton
                  aria-label="menu"
                  size="large"
                  sx={{color: 'White'}}
                  onClick={() => setIsMenuOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {/* <Button
                variant="outlined"
                className="play-now-btn"
                sx={{
                  color: '#35FF6E',
                  border: 2,
                  borderRadius: '30px',
                  fontSize: '20px',
                  height: 58,
                  width: 150,
                }}
              >
                PLAY NOW
              </Button> */}
              <PlayNowButton />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
