const getAllBlogPosts = async () => {
	const response = await fetch("/api/blogposts");
	const jsonResponse = await response.json();
	// console.log(jsonResponse)
	return jsonResponse;
};

const getBlogPostById = async (id) => {
	try {
		const response = await fetch(`/api/blogpost/${id}`);
		const jsonResponse = await response.json();
		// console.log(jsonResponse)
		return jsonResponse;
	} catch (error) {
		return error;
	}
};

const createBlogPost = async (data) => {
	try {
		const response = await fetch("/api/blogpost", {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		const jsonResponse = await response.json();
		return jsonResponse.message;
	} catch (error) {
		return error;
	}
};

const deleteBlogPostById = async (id) => {
	try {
		const response = await fetch(`/api/blogpost/${id}`, {
			method: "DELETE",
			credentials: "include",
		});
		const jsonResponse = await response.json();
		return jsonResponse.message;
	} catch (error) {
		return error;
	}
};

const editBlogPost = async (data) => {
	try {
		const response = await fetch(`/api/blogpost/${data.UUID}`, {
			method: "PUT",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		const jsonResponse = await response.json();
		return jsonResponse.message;
	} catch (error) {
		return error;
	}
};

export {
	getAllBlogPosts,
	getBlogPostById,
	createBlogPost,
	deleteBlogPostById,
	editBlogPost,
};
