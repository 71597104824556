import React from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";

export const CommunityGuidelinesTextBlock = () => {
	return (
		<div className="legal-block">
			<div className="text-block">
				<p class="p1">
					<b>
						DREAMWORLD- CONTENT Standards AND COMMUNITY GUIDELINES
					</b>
				</p>
				<p class="p2">
					<b>Effective: September 10, 2024</b>
				</p>
				<MarkdownPreview
					source={text}
					wrapperElement={{ "data-color-mode": "dark" }}
					style={{
						backgroundColor: "#2e2922",
						maxWidth: "100%",
						fontFamily: "Mulish-Medium",
						fontSize: "1.25rem",
						color: "#f8f6ea",
					}}
				></MarkdownPreview>
			</div>
		</div>
	);
};

const text = `
These Content Standards and Community Guidelines (these "Standards and Guidelines") apply to all users

and/or visitors of the Software, any and all User Content submitted or uploaded in connection with the access

and/or use of the Software (or any part thereof). These Standards and Guidelines are a part of and

incorporated into the DreamWorld Terms of Use (the "Terms"). Capitalized terms used herein but not defined

will have the meanings given in the Terms.

1.  **<u>Content Standards</u>**: User Content must in its entirety comply with the Terms, including, without limitation

these Standards and Guidelines, and all applicable federal, state, local and international laws and regulations.

Without limiting the foregoing, User Content must not contain anything that is or could appear to:

- Be threatening, abusive, bullying, harmful, stalking another user, harassing, or hateful;

- Be offensive, obscene, indecent, inflammatory or otherwise objectionable;

- Be untrue, misleading, libelous, defamatory, invasive to another person's privacy or protected data;

- Be or promote sexually explicit or pornographic material, violence, or discrimination based on race,

ethnicity, sex, religion, nationality, disability, sexual orientation or age;

- Be plagiarized, or infringes any patent, trademark, trade secret, copyright or other intellectual property

or other rights of any other person, this includes (but is not limited to) posting, generating, or creating

any User Content that is or relates to, or otherwise resembles or incorporates, characters, cartoons,

super heroes, video games, movies, singers, celebrities, or other copyrighted, trademarked or third

party content if you do not have the appropriate rights and licenses from the applicable rights-holder;

- Be likely to deceive any person;

- Promote any illegal activity, or advocate, promote or assist any unlawful act.

- Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy

any other person;

- Impersonate any person, or misrepresent your identity or affiliation with any person or organization;

- Be anything that you do not have the legal right to transfer;

- Be anything that you are legally bound not to disclose under contractual or fiduciary relationships

including, but not limited to, inside information or proprietary and confidential business information;

- Be unsolicited, undisclosed, or unauthorized advertising;

- Be software viruses or any other malicious computer code, files, or programs designed to interrupt,

destroy, or limit the functionality of any computer software, hardware, or telecommunication

equipment;

- Be data or information obtained by the unauthorized access of another's computer or account with

which such a posting or submission would constitute unauthorized use; and/or

- Be any other post or submission that violates applicable local, state, national or international law,

regulation or statute (including export laws).

2\. **<u>Community Guidelines</u>**. When you use the Software and/or interact with others through or in connection

with the use of the Software, including through any interactive communication methods available through or in

connection with the use of the Software, be respectful and follow DreamWorld's policies. Treat others as you

would want to be treated and be polite. Freedom of speech and having an opinion does not mean you can be

abusive, hateful, or disruptive. The following sets forth general (but by no means exhaustive) guidelines to

follow when using the Software and/or interacting with other users of the Software:

- Bullying and harassment:

    a. Do not participate in hate speech or be hateful to others based on their race, ethnicity, religion,

    profession, appearance, medical condition, sexual orientation or preference or any other

    discriminatory behavior.

    b. Do not target individuals to degrade, harass, bully, threaten, stalk or shame them.

    c. Do not post or disclose personal information about others unless you have explicit consent from

    the person.

   d. Do not be disruptive, vulgar, abusive, or sexually explicit in your posts, messages, or chats or

otherwise in the use of the Software.

- Sexual or adult-oriented behavior:

   a. Do not use sexually explicit, vulgar, or obscene language.

   b. Do not engage in sexual solicitation.

   c. Do not engage in adult-themed or sexually oriented activities.

- Spam, scam, fraud:

   a. Do not misrepresent or lie about your age and (and report users who appear to be lying about

    theirs!).

   b. Do not impersonate any person or entity (including, without limitation, DreamWorld or any

    DreamWorld staff), use a fictitious name, or falsely state or otherwise misrepresent your affiliation

    with any person or entity (including, without limitation, DreamWorld or any DreamWorld staff).

   c. Do not ask for another user&#39;s password, log-in credentials, Access Code, or any Account

    information.

   d. Do not share, give away, trade, or sell your Account, usernames and/or avatar, or your Access

    Code to the Software.

   e. Do not post links to fraudulent websites or phishing sites.

   f. Do not harvest user names, phone numbers, addresses, or, email addresses or otherwise collect

    or attempt to collect any other personal information of any users.

   g. Do not post or engage in any unsolicited or unauthorized advertising, promotional materials, &#39;junk

    mail&#39;, &#39;spam&#39;, &#39;chain letters&#39;, &#39;pyramid schemes&#39;, 'multi-level marketing schemes', or any other form

    of such solicitation.

   h. Do not engage or behave in any manner that is detrimental to the enjoyment of the Software by

    other users, including, but not limited to, "griefing," cheating, game abandonment, game sabotage,

    scamming, and/or social engineering.

3\. **<u>Consequences of Violation</u>**. Without limiting any other rights or remedies we may have, if we determine

that you have violated any part of these Standards and Guidelines, we may remove any offending content or

suspend or terminate your access and/or use of the Software (or any part thereof). We may intercept or block

any content or traffic where the Software is being used unlawfully or not in accordance with the Terms and/or

these Standards and Guidelines. Our right to suspend or terminate your access and/or use of the Software

applies even if a violation is committed unintentionally or without your authorization.

4\. **<u>Reporting</u>**. If you become aware of any violation of these Standards and Guidelines, please immediately

notify us and provide us with assistance, as requested, to stop or remedy the violation. To report any violation

of these Standards and Guidelines, please contact us at [email].

QUESTIONS

If you have any questions about these Standards and Guidelines, please contact us by email at:

support@playdreamworld.com.
`;
