import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { GenericPictureTitle } from "../../components/header/GenericPictureTitle";
import { CookieTextBlock } from "./CookieText";
import { LinksBlock } from "../../components/footer/LinksBlock";

export function CookiePolicyPage() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>

			<GenericPictureTitle TitleName="COOKIE POLICY"></GenericPictureTitle>
			<CookieTextBlock />
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
