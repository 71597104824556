import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const PrivacyTextBlock = () => {
	return (
		<div className="legal-block">
			<div className="text-block">
				<p class="legal-text">
					<strong>DreamWorld - Privacy Notice</strong>
					<br />
					Last updated: May 30, 2023
				</p>
				<p class="legal-text">
					<br />
					Please read this Privacy Notice carefully. It provides
					important information about your personal information and
					tells you about your rights under applicable privacy laws.
					If you have any questions, comments, or concerns regarding
					this Privacy Notice and/or our data practices, please
					contact us.
				</p>
				<p class="legal-text">
					<br />
					If you are a resident of California or Nevada or visiting us
					from the European Economic Area or United Kingdom (“UK”),
					you should read this policy and the applicable sections
					below for residents of California and Nevada and visitors
					from the UK and EEA.
				</p>
				<p class="legal-text"> </p>
				<p class="legal-text">
					BY ACCESSING OR USING THE SERVICES (OR ANY PART THEREOF), OR
					SUBMITTING INFORMATION IN CONNECTION WITH YOUR USE OF THE
					SERVICES (OR ANY PART THEREOF), YOU ACKNOWLEDGE AND AGREE
					THAT YOU HAVE READ THIS PRIVACY NOTICE.
				</p>
				<p class="legal-text">
					<br />
					Table of Contents
					<br />
					Introduction
					<br />
					Changes to this Privacy Notice
					<br />
					Information We Collect
					<br />
					Children’s Privacy
					<br />
					Sensitive Data
					<br />
					How DreamWorld Uses Personal Information
					<br />
					Sharing Personal Information
					<br />
					Marketing Communications
					<br />
					Your Choices
					<br />
					Third Party Social Plug-Ins
					<br />
					External Websites
					<br />
					Data Retention
					<br />
					Data Security and Protection
					<br />
					California Privacy Rights – Shine the Light
					<br />
					Nevada Privacy Rights
					<br />
					International Transfers
					<br />
					Additional European and International Data Rights
					<br />
					How to Contact Us
				</p>
				<p class="legal-text">
					<br />
					1. Introduction
					<br />
					DreamWorld Realities, Inc. (“DreamWorld”, “we”, “our” or
					“us”) owns and operates: (a) DreamWorld’s website located at
					www.playdreamworld.com, (the “Site”), and (b) the
					DreamWorld’s proprietary interactive software game (the
					“Software”).
				</p>
				<p class="legal-text">
					When we refer to the “Services” throughout this Privacy
					Notice, we mean the Site, Software, and any other products,
					websites, services, applications and/or other products that
					include a link and/or reference to this Privacy Notice, and
					any related services and/or new features and/or
					functionality provided by us through or in
					<br />
					connection with any of the foregoing.
				</p>
				<p class="legal-text">
					2. Changes to this Privacy Notice
					<br />
					DreamWorld reserves the right to update or modify this
					Privacy Notice at any time. Except for material changes as
					described below in this Section, all updates and
					modifications to these documents will be effective from the
					day they are posted on the Site at
					https://www.playdreamworld.com/pages/privacy-policy. If we
					make
					<br />
					any material changes to this Privacy Notice, we will notify
					you of these changes by sending a notification to the email
					address we have on file for you, and may also post a
					prominent notice of any such changes on the Site.
				</p>
				<p class="legal-text">
					<br />
					It is your responsibility to regularly visit and review this
					Privacy Notice. If you do not agree to any updates or
					modifications to the Privacy Notice, terminate your Account
					and cease all use of the Services. Your continued use of the
					Services after we have posted the updated Privacy Notice,
					or, in the event of material changes, ten (10) days
					following the date we first notified you of such material
					changes via email or the date we posted the notice of such
					changes on the Site, as applicable, signifies to us that you
					acknowledge and agree to be bound by the revised Privacy
					Notice.
				</p>
				<p class="legal-text"> </p>
				<p class="legal-text">
					3. Information We Collect
					<br />
					How and what personal information we collect in connection
					with the use of the Services depends on how and why you use
					the Services. The term “personal information” or “personal
					data” means any information about an individual from which
					that person may be identified. For example, it may include a
					person’s name, telephone number, email address, or payment
					information, and in some jurisdictions, IP address. It does
					not include data from which the identity of
					<br />
					an individual has been definitively removed along with any
					identifiers connected to such individual (also known as
					anonymous or anonymized data). Generally, DreamWorld
					collects personal information when you:
				</p>
				<p class="legal-text">
					Visit and/or browse the Site,
					<br />
					Purchase a license to the Software;
					<br />
					Purchase any add-on content, features, functionality, and/or
					features we make available for purchase in-game
					<br />
					for use in the Software (collectively, “Add-ons”);
					<br />
					Install and/or download the Software;
					<br />
					Register an account to access and use the Software (an
					“Account”)
					<br />
					Access and/or use the Software;
					<br />
					Interact with and/or use support and other services provided
					by us as part of or in connection with the
					<br />
					Services,
					<br />
					Sign up for our marketing communications and/or newsletters,
					and/or
					<br />
					Communicate with us directly.
					<br />
					In some instances, we collect information directly from you
					as explained in the Section Information Provided by
					<br />
					You Directly to DreamWorld, and in others, we may receive
					information from third parties (which may include
					<br />
					other users of the Services) as explained in the Section
					Information Collected From Third Parties. In addition,
					<br />
					in certain circumstances, you will not provide any
					information to us directly, in which case we automatically
					<br />
					collect certain information as explained Information
					Collected Indirectly.
					<br />
					a. Information Provided by You Directly to DreamWorld
					<br />
					The personal information we collect directly from you
					depends on how and why you use the Services. For
					<br />
					instance, the information that you provide when you visit
					and/or browse the Site or sign up for our marketing
					<br />
					communications is more limited than the information you
					provide when you register an Account.
					<br />
					Please be advised that we may ask you to update your
					information from time to time in order to keep it
					<br />
					accurate.
					<br />
					Account &amp;amp; Registration Information
					<br />
					When you register an Account, DreamWorld may collect the
					following personal information directly from you:
					<br />
					Your identity (including your first name and last name);
					<br />
					Your email address; and/or
					<br />
					Your username and/or avatar, password and/or other login
					credentials.
					<br />
					Payment and Transaction Information
					<br />
					When you purchase a license to the Software and/or purchase
					Add-ons, DreamWorld may collect limited
					<br />
					payment information, such as your credit card, bank account
					information, billing address, as well as information
					<br />
					regarding such purchases.
					<br />
					Note that we do not directly collect or store any payment
					information. Payment transaction information is
					<br />
					processed through our online store, hosted and provided by
					Shopify using third-party PCI-compliant, payment processors
					(currently, Stripe), on our behalf in order to collect fees
					payable by you and complete transactions
					<br />
					for your purchases.
				</p>
				<p class="legal-text">
					<br />
					The information provided to DreamWorld only includes partial
					payment or credit card information, and limited
					<br />
					transaction information via our payment processors’ portals.
					Please review the following Privacy Policies of
					<br />
					our current online store and payment providers to learn more
					about how they collect, process and protect your
					<br />
					personal information:
					<br /> Shopify: https://www.shopify.com/legal/privacy
					<br /> Stripe: https://stripe.com/privacy
				</p>
				<p class="legal-text">
					<br />
					Other Information You May Provide to Us
					<br />
					In addition to the above scenarios, there may be certain
					features of the Services which allow you to provide
					<br />
					additional information to us directly, which may include:
					<br /> Your name (first and last) and/or email address,
					when you sign up for our marketing communications
					<br />
					and/or newsletters;
					<br /> Messages, photos, video, audio, images, data,
					information, text and/or any other content or materials
					<br />
					submitted by you when you use any interactive communication
					and/or chat features of the Services
					<br />
					and/or comment on any blogs and/or forums we make available
					through the Services; and/or
					<br />
					Information, data, content, documents and/or materials you
					provide to us when you fill out forms, answer
					<br />
					surveys, or contact us (such as your feedback, requests for
					support, or other communications with us).
					<br />
					b. Information Collected from Third Parties
					<br />
					In some instances, we may process and/or receive personal
					information from third parties, which consists of:
					<br /> Data from our service providers, such as
					transactional data from providers of payment services,
					and/or
					<br />
					from our advertising partners as described in this Privacy
					Notice.
					<br />
					c. Information Collected Indirectly
					<br />
					i. Usage Information
					<br />
					We, or our authorized third-party service providers,
					automatically collect technical and/or analytics information
					<br />
					about how you use and/or interact with our Services
					(collectively, “Usage Information”).
					<br />
					Usage Information that we collect consists of information
					about our users’ interactions with the Services,
					<br />
					including, usage and in-game activities while using the
					Software. Usage Information is collected while you are
					<br />
					using and/or accessing the Services, and may include
					information about the device and/or platform (e.g., iOS
					<br />
					or Android), and/or the web browser, used by you to interact
					with and/or access the Services, your IP address,
					<br />
					access dates and times, information about your approximate
					location (as determined through your IP address),
					<br />
					device information, device event information, game-play and
					screen recordings, crash data, and log files and
					<br />
					data.
				</p>
				<p class="legal-text">
					<br />
					Usage Information allows DreamWorld to understand how users
					are interacting with and using the Services
					<br />
					(which may include administrative and support communications
					with us), and other actions taken in connection
					<br />
					with the use of the Services. We use this information for
					our internal purposes, specifically to operate,
					<br />
					maintain, secure and improve the Services.
					<br />
					Typically, all Usage Information is collected in anonymous
					form and does not identify you personally. To the
					<br />
					extent any Usage Information is linked or tied to personally
					identifiable information, such information is deemed
					<br />
					“personal data” and we will use it and protect it in
					accordance with this Privacy Notice.
					<br />
					We may also use this information to provide you with
					notifications, recommendations, and information about
					<br />
					specific features of the Services and/or additional
					products, services, or features we believe may be of
					interest
					<br />
					to you.
				</p>
				<p class="legal-text">
					<br />
					ii. Cookies and Similar Technologies
					<br />
					We or authorized third parties may collect certain
					information (including, without limitation, Usage
					Information)
					<br />
					by automated means using technologies such as cookies, web
					beacons, embedded scripts, pixels, browser
					<br />
					analysis tools, server logs, and mobile identifiers;
					however, even though we use these technologies to track
					<br />
					the use of the Services, DreamWorld does not directly track
					users across third party websites, provided that,
					<br />
					certain targeting &amp;amp; advertising partners (as
					described below) may track you across websites. For more
					<br />
					information on our use of these technologies, see our Cookie
					Policy.
				</p>
				<p class="legal-text">
					iii. Targeting &amp;amp; Advertising
					<br />
					We may use third party advertising partners (e.g., Facebook,
					Inc. and Google, Inc.) who use
					<br />
					targeting/advertising cookies and similar technologies to
					deliver advertisements that are more relevant to you
					<br />
					and your interests. They are also used to limit the number
					of times you see an advertisement as well as help
					<br />
					measure the effectiveness of the advertising campaigns. They
					are usually placed by advertising networks with
					<br />
					the website operator’s permission. They remember that you
					have visited a website and this information is
					<br />
					shared with other organizations such as advertisers. For
					more information on our and/or third party advertising
					<br />
					partners’ use of these targeting &amp;amp; advertising
					cookies and similar technologies, please see our Cookie
					Policy.
				</p>
				<p class="legal-text">
					<br />
					a. Aggregated Data
					<br />
					With the personal information and other data (including,
					Usage Information) collected by us, we process
					<br />
					“Aggregated Data”, such as statistical or demographic data.
					Aggregated Data may be derived from personal
					<br />
					information, but is not considered personal information
					under the law if it does not directly or indirectly reveal
					<br />
					your identity. If we combine or connect Aggregated Data with
					your personal information so that it can directly
					<br />
					or indirectly identify you, we treat the combined data as
					personal information, which will be processed in
					<br />
					accordance with this Privacy Notice.
				</p>
				<p class="legal-text">
					<br />
					4. Children’s Privacy
					<br />
					DreamWorld does not target the Services to, nor knowingly
					collect personal information, persons under the
					<br />
					age of 13 or the equivalent age as specified by law in the
					applicable jurisdiction (e.g., 16 years of age if you are
					<br />
					located in the EEA). Therefore, we ask you not to provide us
					with personal information of persons under the
					<br />
					age of 13 or the equivalent age as specified by law in your
					jurisdiction. If we learn that personal information of
					<br />
					persons under the age of 13 or the equivalent age as
					specified by law in the applicable jurisdiction, has been
					<br />
					collected on or through the Services, then we may deactivate
					the account or otherwise terminate access to the
					<br />
					Services and/or make the user content inaccessible.
				</p>
				<p class="legal-text">
					<br />
					5. Sensitive Data
					<br />
					DreamWorld does not require you to provide any sensitive
					data about your race or ethnicity, religious or
					<br />
					philosophical beliefs, sex life, sexual orientation,
					political opinions, trade union membership, health and
					<br />
					genetics, or biometric data to use the Services.
				</p>
				<p class="legal-text">
					<br />
					6. How DreamWorld Uses Personal Information
					<br />
					Our mission is to provide safe, efficient and high-quality
					services, and we, or our authorized third party service
					<br />
					providers who assist us in providing the Services, process
					your personal information for this purpose. We
					<br />
					process your personal data for the reasons and on the legal
					basis as follows:
					<br />
					In order to perform the Services under the contract we are
					about to enter into or have entered into with you.
					<br />
					For example, when you register to use our Services, that’s a
					contract. This may also include disclosure to the
					<br />
					third parties who help us perform our obligations to you in
					connection with your use of the Services, such as
					<br />
					hosting providers, and payment processors.
					<br />
					Where it is necessary for our legitimate interests (or those
					of a third party), and your interests and fundamental
					<br />
					rights do not override those interests. For example, for
					security purposes and protection against fraud.
					<br />
					Where we need to comply with a legal or regulatory
					obligation. For example, keeping records of our sales for
					<br />
					tax compliance.
					<br />
					<br />
					If we have obtained your prior consent (for example, when
					you subscribe to our newsletter or participate in our
					<br />
					surveys or marketing campaigns). Please note that for this
					specific legal basis, you have the right to withdraw
					<br />
					your consent at any time.
					<br />
					Depending on how you use the Services, we use your personal
					information in the following instances:
					<br />
					To host and provide the Site (performance of a contract);
					<br />
					To perform and provide the Services to you (performance of a
					contract);
					<br />
					Deliver direct marketing communications to you regarding our
					products and services that we may think
					<br />
					are of interest to you (for our legitimate business purposes
					and with your prior consent where required
					<br />
					by law when you are not an existing customer);
					<br />
					Respond to your queries and requests, or otherwise
					communicate directly with you (performance of a<br />
					contract or sometimes necessary for our legitimate
					interests);
					<br />
					Detect any fraudulent or illegal activity against you, our
					other customers and users, and/or DreamWorld
					<br />
					(necessary for our legitimate interests);
					<br />
					Perform system maintenance and upgrades, and enable new
					features (performance of a contract or
					<br />
					sometimes necessary for our legitimate interests);
					<br />
					Provide information to regulatory bodies when legally
					required, and only as outlined below in Legal
					<br />
					Obligations and Security (necessary for compliance with a
					legal obligation).
				</p>
				<p class="legal-text">
					<br />
					7. Sharing Personal Information
					<br />
					Aside from disclosing your information to those of our
					personnel who are authorized to process the information
					<br />
					in order to provide the Services and who are committed to
					confidentiality, we disclose your personal
					<br />
					information only to the third parties as described below.
					<br />
					Third-Party Service Providers
					<br />
					We share personal information with third parties that
					provide services to us that help us in the operation,
					<br />
					provision, administration and management of the Services
					(“Service Providers”), and to otherwise operate our
					<br />
					business. Depending on how you use the Services, the
					following categories of third parties collect data on our
					<br />
					behalf or receive personal information:
					<br />
					Hosting services providers (for example, we use Shopify to
					host our Site),
					<br />
					Providers of certain functionality and/or features of our
					Software (for example, we use Vivox (a service
					<br />
					of Unity Technologies) that enables users to voice chat with
					other players while using the Software),
					<br />
					Analytics providers,
					<br />
					Payment processing providers (currently Stripe, through
					Shopify);
					<br />
					Providers of our online store (currently, Shopify);
					<br />
					Advertising and marketing partners,
					<br />
					Providers of business operations and communication tools,
					<br />
					Other third-party service providers that help us provide
					features and functions for the Services (e.g.,
					<br />
					customer support providers), and
					<br />
					Professional service providers, such as auditors, lawyers,
					consultants, accountants and insurers.
					<br />
					For a list of all Service Providers we use, please contact
					us. We require all Service Providers to respect the
					<br />
					security of your personal information and to treat it in
					accordance with the law.
					<br />
					To other Users of the Services
					<br />
					Depending on how you use the Services, we may share your
					information with other users of the Services.
					<br />
					Examples of when we may share your information with other
					users includes:
					<br /> When you create a username and/or avatar when
					registering an Account, your username and/or avatar
					<br />
					will be available to other users of the Software.
					<br />
					<br /> If you communicate with other users through the
					chat, messaging and/or communication features of the
					<br />
					Services, any personal information submitted or transmitted
					in connection with such communication
					<br />
					features will be available to anyone engaged in
					communications with you and/or who has access to
					<br />
					those communication features. Please note that if you enable
					or turn-on the voice chat feature while
					<br />
					using the Software, the voice chat feature and your
					microphone will remain on as the default setting for
					<br />
					your current session and any subsequent sessions until you
					disable or turn-off voice chat. You can
					<br />
					view your microphone and voice chat options in the settings
					menu of the Software.
					<br /> If you comment and/or post on any of our blogs, your
					comments and/or posts will be publicly available.
					<br />
					Please be aware that other users with whom you choose to
					share your information may use your personal
					<br />
					information differently than we do, and we do not control
					their use of your information. DreamWorld will not be
					<br />
					able to contain or retrieve personal information once it has
					been shared or disclosed with other users, and
					<br />
					DreamWorld will have no responsibility or liability for any
					consequences that may result because you have
					<br />
					released or shared personal information with others.
					<br />
					Please make your choice to share your information carefully.
					<br />
					Business Transfers
					<br />
					We may also share data with third parties to whom we choose
					to sell, transfer, or merge parts of our business
					<br />
					or our assets. Alternatively, we may seek to acquire other
					businesses or merge with them. If a change
					<br />
					happens to our business, then the new owners may use your
					personal information in the same way as set out
					<br />
					in this Privacy Notice.
					<br />
					Affiliates and Subsidiaries
					<br />
					Personal information that we collect about you through or in
					connection with your use of the Services may be
					<br />
					shared with the employees, contractors, and agents of
					DreamWorld and our affiliated and subsidiary entities
					<br />
					(“Affiliates”) who are involved in providing or improving
					the Services that we offer to you. We obligate the
					<br />
					employees, contractors and agents of DreamWorld and our
					Affiliates to ensure the security and confidentiality
					<br />
					of your personal information and to act on that personal
					information only in a manner consistent with this
					<br />
					Privacy Notice.
					<br />
					Legal Obligations and Security
					<br />
					If we are required to disclose personal information by law,
					such as pursuant to a subpoena, warrant or other
					<br />
					judicial or administrative order, our policy is to respond
					to requests that are properly issued by law enforcement
					<br />
					within the United States. Under such circumstances, unless
					prohibited by applicable law, we will attempt to
					<br />
					provide you with prior notice that a request for your
					information has been made in order to give you an
					<br />
					opportunity to object to the disclosure. We will attempt to
					provide this notice by email, if you have given us an
					<br />
					email address. However, government requests may include a
					court-granted non-disclosure order, which
					<br />
					prohibits us from giving notice to the affected individual.
					In cases where we receive a non-disclosure order, we
					<br />
					will notify you when it has expired or once we are
					authorized to do so.
					<br />
					Note that if we receive information that provides us with a
					good faith belief that there is an exigent emergency
					<br />
					involving the danger of death or serious physical injury to
					a person, we may provide information to law
					<br />
					enforcement trying to prevent or mitigate the danger (if we
					have it), to be determined on a case-by-case basis.
					<br />
					With your Consent
					<br />
					There may be situations where you are asked to consent to
					share personal information with third parties for
					<br />
					additional reasons not included in this Privacy Notice. In
					such event, we will only share such personal
					<br />
					information if we have received your prior consent and only
					for the purposes as listed in the request to share
					<br />
					such information.
				</p>
				<p class="legal-text">
					<br />
					8. Marketing Communications
					<br />
					If you are a registered user and have not otherwise opted
					out (or with your consent where required by
					<br />
					applicable law), or if you have opted in to receive direct
					marketing emails from us, we may use your personal
					information to send you marketing information about the
					Services, other DreamWorld products and services,
					<br />
					new product releases, and new feature releases of the
					Services that we think may interest you. We carry out
					<br />
					direct marketing by email.
					<br />
					If you no longer wish to receive marketing communications,
					you have the right at any time to opt out as further
					<br />
					explained in Your Choices.
				</p>
				<p class="legal-text">
					<br />
					9. Your Choices
					<br />
					Accessing, Updating and Correcting Personal information
					<br />
					If you would like to correct or update certain personal
					information (such as your contact information), you can
					<br />
					update this information in your Account settings page. If
					you cannot access your Account page or there is
					<br />
					other information that you have provided to us that you
					cannot access through your Account page that you
					<br />
					wish to correct and/or update please contact us and we will
					use reasonable efforts to correct and/or update
					<br />
					such information.
					<br />
					Additional European and International Data Rights
					<br />
					If you are a resident of the European Economic Area or
					another country with data protection laws that provide
					<br />
					for certain data subject rights, you may have additional
					rights you can exercise as described here.
					<br />
					Direct Marketing
					<br />
					You may manage the receipt of marketing and
					non-transactional communications by clicking on the
					<br />
					“unsubscribe” link located on the bottom of any of our
					marketing e-mails. We will use commercially reasonable
					<br />
					efforts to process such requests in a timely manner. Note
					that you cannot opt out of receiving transactional e-
					<br />
					mails or communications related to the Services (e.g.,
					requests for support), which, for clarification, are not
					<br />
					marketing communications.
					<br />
					Cookies &amp;amp; Targeted Advertising
					<br />
					You can manage your cookie and tracking preferences as
					described in our Cookie Policy.
				</p>
				<p class="legal-text">
					<br />
					10. Third Party Social Plug-Ins
					<br />
					On or through the Services, we provide third-party “share”
					buttons which enable you to share certain content
					<br />
					via social media sites (e.g., Facebook, Twitter, Instagram,
					YouTube, and LinkedIn). These “share” buttons
					<br />
					may function as web beacons when you interact with the
					button. Please note that when you “share” using the
					<br />
					buttons, you may send to the third party provider of the
					“share” button the information that you are viewing. If
					<br />
					you are not logged into your account with the third party
					provider, then the third party may not know your
					<br />
					identity. If you are logged in to your account with the
					third party, then the third party may be able to link
					<br />
					information or actions about your interactions with the
					Services to your account with the applicable third party
					<br />
					provider. Please refer to each third party’s privacy
					policies to learn more about its data practices.
				</p>
				<p class="legal-text">
					<br />
					11. External Websites
					<br />
					On or through the Services, we may provide or make
					available, for informational purposes only, links to other
					<br />
					websites or resources with which we do not have a
					contractual relationship and over which we do not have
					<br />
					control (“External Websites”). Such links are not paid
					advertisements, nor do they constitute an endorsement
					<br />
					by DreamWorld of those External Websites, and are provided
					to you only as a convenience. By clicking on
					<br />
					links to External Websites, the operators of the External
					Websites may collect your personal information. We
					<br />
					are not responsible for the content or data collection
					practices of those External Websites, and your use of
					<br />
					External Websites is subject to their respective terms of
					use and privacy policies.
				</p>
				<p class="legal-text">
					<br />
					12. Data Retention
					<br />
					Personal information is processed for the period necessary
					to fulfill the purposes for which it is collected (for
					<br />
					example, in connection with the Services provided to you
					pursuant to the applicable terms and/or agreement entered
					into with DreamWorld with respect to your use of the
					Services), to comply with legal and regulatory
					<br />
					obligations and for the duration of any period necessary to
					establish, exercise or defend any legal rights.
					<br />
					In order to determine the most appropriate retention periods
					for your personal information, we consider the
					<br />
					amount, nature and sensitivity of your information, the
					reasons for which we collect and process your personal
					<br />
					information, and applicable legal requirements.
					<br />
					In some instances, we may choose to anonymize personal
					information instead of deleting it, for statistical use,
					<br />
					for instance. When we choose to anonymize, we make sure that
					there is no way that the personal information
					<br />
					can be linked back to any specific individual.
				</p>
				<p class="legal-text">
					<br />
					13. Data Security and Protection
					<br />
					We have put in place reasonable and appropriate security
					measures designed to prevent your personal
					<br />
					information from being accidentally lost, used or accessed
					in an unauthorized way, altered or disclosed. For
					<br />
					example, we use encryption, secure socket layer (SSL),
					firewalls, and password protection. In addition, we limit
					<br />
					access to personal information to those employees, agents,
					contractors and the third parties who have a<br />
					business need-to-know.
					<br />
					We also have procedures in place to deal with any suspected
					data security breach. However, no method of
					<br />
					transmission over the Internet, or method of electronic
					storage, is 100% secure, and while we take reasonable
					<br />
					steps to provide secure services, by using the Services, you
					understand and assume the risks associated with
					<br />
					your activities on the internet.
					<br />
					In addition, you have responsibility and control over
					protecting your personal information. For example, you,
					<br />
					and not DreamWorld is responsible for safeguarding your
					password, and other authentication information you
					<br />
					use to access the Services. You should not disclose your
					password and/or authentication information to any
					<br />
					third party and should immediately contact us of any
					unauthorized use of your password or you have reason to
					<br />
					believe that your information and/or Account is no longer
					secure.
					<br />
					In addition, as described in this Privacy Notice, DreamWorld
					cannot secure, and has no responsibility for, any
					<br />
					personal information that you share or release on your own
					or that you request us to share or release or share
					<br />
					with other users of the Services.
				</p>
				<p class="legal-text">
					<br />
					14. California Privacy Rights - Shine the Light
					<br />
					DreamWorld does not share your personal information with any
					unaffiliated third party for its direct marketing
					<br />
					purposes without your express consent.
					<br />
					California residents who have provided us with personal
					information have the right (under California Civil
					<br />
					Code§ 1798.83) to request and obtain from us, once each
					year, the details of any personal information we
					<br />
					shared with a third party for that third party’s direct
					marketing purposes during the prior calendar year. The
					<br />
					details would include the categories of personal information
					and the names and addresses of the third party
					<br />
					with which it was shared.
					<br />
					To request information about this sharing, you may submit a
					request with &amp;quot;Your California Privacy
					Rights&amp;quot; in
					<br />
					the subject line, along with your first and last name, and
					complete mailing address (including street address,
					<br />
					city, state, and zip code) by either: sending an email
					message or writing to us at the email and/or mailing
					<br />
					address specified in the How to Contact Us Section.
					<br />
					If you do not submit and send your request with complete
					information as set forth above, we will not process
					<br />
					it.
				</p>
				<p class="legal-text">
					<br />
					15. Nevada Privacy Rights
					<br />
					Under Nevada law, certain Nevada residents may opt out of
					the sale of covered personal information (which
					<br />
					includes first and last name, address, email address, phone
					number, Social Security Number, or an identifier
					<br />
					that allows a specific person to be contacted either
					physically or online) to a person for that person to license
					<br />
					or sell such information to additional persons.
					<br />
					<br />
					DreamWorld does not currently sell your covered information
					as defined under applicable Nevada law;
					<br />
					however, you may submit an opt-out request, which we will
					honor as required by Nevada law should we
					<br />
					engage in such a sale in the future.
					<br />
					You may submit an opt-out request by sending an email
					message or writing to us at the email and/or mailing
					<br />
					address specified in the How to Contact Us Section; along
					with your full name, any user name, and complete
					<br />
					mailing address (including street address, city, state, and
					zip code), email address (so that we can contact
					<br />
					you, if needed, in connection with the request) and
					confirmation that you are a Nevada resident.
					<br />
					You must provide us with information reasonably requested by
					us to verify your identity and the authenticity
					<br />
					of the request. We typically process these requests within
					60 days from the day we receive it (and will notify
					<br />
					you within that period if we need an additional 30 days to
					do so).
				</p>
				<p class="legal-text">
					<br />
					16. International Transfers
					<br />
					DreamWorld is based in the United States. The personal
					information that we collect are sent to and stored on
					<br />
					servers located in the United States. Such storage is
					necessary in order to process the information.
					<br />
					DreamWorld operates globally and may transfer the personal
					data that we collect from you to our other offices
					<br />
					and/or to the third parties mentioned in the circumstances
					described above, which may be situated outside of
					<br />
					your country or regional area, and may be processed by staff
					operating outside of your country or regional
					<br />
					area. In particular, information provided to us or collected
					by us likely will be transferred to and processed in
					<br />
					the United States by us or our Affiliates and our respective
					agents and contractors. The data protection laws of
					<br />
					the United States or other countries may not be as
					comprehensive or equivalent to those in your country of
					<br />
					residence.
					<br />
					The European Union’s General Data Protection Regulation
					(“GDPR”) allows for transfer of personal data from
					<br />
					the European Union to a third country in certain situations.
					We rely on legally-provided mechanisms to lawfully
					<br />
					transfer personal information across borders. For example,
					we may enter into the EU Standard Contractual
					<br />
					Clauses adopted by the EU Commission. More information about
					the Standard Contractual Clauses is available here.
				</p>
				<p class="legal-text">
					<br />
					17. Additional European and International Data Rights
					<br />
					The European Union’s General Data Protection Regulation and
					certain other countries’ data protection laws provide
					certain rights for data subjects. If you are a resident of
					the European Economic Area, the United Kingdom or another
					country with data protection laws that provide for certain
					data subject rights, you may submit a request to exercise
					your rights.
					<br />
					We respond to all requests we receive from individuals
					wishing to exercise their data rights in accordance with
					applicable data protection laws. Depending on your country
					of residence, your rights may include.
					<br />
					The right to be informed – that’s an obligation on us to
					inform you how we use your personal data (and that’s what
					we’re doing in this Privacy Notice);
					<br />
					The right of access – that’s a right to make what’s known as
					a ‘data subject access request’ for a copy of the personal
					data we hold about you;
					<br />
					The right to rectification – that’s a right to make us
					correct personal data about you that may be incomplete or
					inaccurate (though we generally recommend first making any
					changes in your account);
					<br />
					The right to erasure (also known as the ‘right to be
					forgotten’) – that’s where in certain circumstances you can
					ask us to delete the personal data we have about you (unless
					there’s an overriding legal reason we need to keep it);
					<br />
					The right to restrict processing – that’s a right for you in
					certain circumstances to ask us to suspend processing
					personal data;
					<br />
					The right to data portability – that’s a right for you to
					ask us for a copy of your personal data in a common format
					(for example, a .csv file);
					<br />
					The right to object – that’s a right for you to object to us
					processing your personal data (for example, if you object to
					us processing your data for direct marketing); and Rights in
					relation to automated decision-making and profiling – that’s
					a right you have for us to be transparent about any
					profiling we do, or any automated decision-making.
					<br />
					Withdraw Consent—that’s the right to revoke any consent you
					may have previously given us at any time, if we have
					collected and processed your personal information with your
					consent. Withdrawing your consent will not affect the
					lawfulness of any processing we conducted prior to your
					withdrawal, nor will it affect processing of your personal
					information conducted in reliance on lawful processing
					grounds other than consent.
					<br />
					File a complaint—that’s the right to file a complaint with a
					supervisory authority about our collection and processing of
					your personal information.
					<br />
					Exercising Your Rights
					<br />
					These rights are subject to certain rules around when you
					can exercise them. If you are located in the European
					Economic Area and wish to exercise any of the rights set out
					above, please contact us.
					<br />
					You will not have to pay a fee to access your personal data
					(or to exercise any of the other rights) unless your request
					is clearly unfounded, repetitive or excessive.
					Alternatively, we may refuse to comply with your request in
					these circumstances.
					<br />
					We may need to request specific information from you to help
					us confirm your identity and ensure your right to access
					your personal data (or to exercise any of your other
					rights). This is a security measure to ensure that personal
					data is not disclosed to any person who has no right to
					receive it.
					<br />
					We will respond to all legitimate requests within one month.
					Occasionally it may take us longer than a month if your
					request is particularly complex or you have made a number of
					requests. In this case, we will notify you and keep you
					updated as required by law.
					<br />
					In addition, if you no longer wish to receive our
					marketing/promotional information, we remind you that you
					may withdraw your consent to direct marketing at any time
					directly from the unsubscribe link included in each
					electronic marketing message we send to you. If you do so,
					we will promptly update our databases and will take all
					reasonable steps to meet your request at the earliest
					possible opportunity, but we may continue to contact you to
					the extent necessary for the purposes of providing the
					Services as requested and/or ordered by you or necessary
					updates regarding and the Services used by you.
					<br />
					Finally, you have the right to make a complaint at any time
					to the supervisory authority for data protection issues in
					your country of residence. We would, however, appreciate the
					chance to deal with your concerns before you approach the
					supervisory authority, so please contact us first.
				</p>
				<p class="legal-text">
					<br />
					18. How to Contact Us
					<br />
					If you have any questions about this Privacy Notice,
					including any requests to exercise your legal rights, please
					contact us as follows:
					<br />
					By email at: support@playdreamworld.com; Subject Line:
					Privacy Request
					<br />
					By mail at: DreamWorld Realities, Inc.
					<br />
					Attn: DreamWorld Privacy Request
					<br />
					<span data-mce-fragment="1">
						440 N BARRANCA AVE #2952 COVINA, CA 91723{" "}
					</span>
				</p>
			</div>
		</div>
	);
};
