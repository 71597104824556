import React from "react";
import { StylishArrowDown } from "../widget/StylishArrowDown";
import { GameplayTrailerBlock } from "./GameplayTrailerBlock";
import useId from "@mui/material/utils/useId";

export function IntroBlock() {
	const id = useId();

	const onButtonPress = () => {
		const nextItem = document.getElementById(id).nextSibling;
		var rect = nextItem.getBoundingClientRect();

		var scrollToPos = window.scrollY + rect.top;
		console.log(scrollToPos);
		window.scrollTo({ top: scrollToPos, behavior: "smooth" });
	};

	return (
		<div id={id} className="intro-frame">
			<GameplayTrailerBlock></GameplayTrailerBlock>
			<div className="meet-the-team-text">
				<div className="section-header">
					<img
						className="header-style-graphic-right"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-left.png"
					></img>

					<div className="SectionHeaderTitle">
						Dream Big, Build Bigger
					</div>
					<img
						className="header-style-graphic-left"
						src="assets/img/new_design/GraphicAssets/Header-style-graphic-right.png"
					></img>
				</div>
				<p className="welcome-text">
					Welcome to DreamWorld, a groundbreaking sandbox MMORPG that
					blurs the lines between fantasy and reality. In this vast,
					ever-evolving world, players awaken as mystical beings,
					venturing into a dynamic realm that seamlessly integrates
					elements of survival, sandbox, role-playing, city-building,
					and strategy games.
					<br />
					<br />
					Unlike traditional MMORPGs, DreamWorld empowers players to
					explore diverse play styles, from farming, hunting, and
					crafting to orchestrating the construction of cities and
					shaping the world through strategic prowess. The game's
					skill system allows characters to evolve based on individual
					preferences, ensuring a unique journey for every player.
					<br />
					<br />
					DreamWorld is a realm of collaboration, where players can
					unite to tame the wilderness, construct impressive towns and
					cities, and even forge empires across continents and oceans.
					In these higher echelons, DreamWorld transforms into a
					deeply social and strategic experience, where alliances are
					formed, and the destiny of entire virtual civilizations
					hangs in the balance. Welcome to DreamWorld, where
					imagination knows no bounds.
				</p>
			</div>
			<StylishArrowDown onButtonPress={onButtonPress} />
		</div>
	);
}
