import {useState, useEffect} from 'react';
import MDEditor from '@uiw/react-md-editor';
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Alert,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../../components/header/Header';
import {createPostImg} from '../../api/ImageStorage';
import {getBlogPostById, editBlogPost} from '../../api/BlogPost';
import {deletePostImg} from '../../api/ImageStorage';


function CheckSessionValid(){
    // useEffect(() => {
    //     let email = Cookies.get("DWsessionEmail")
    //     if(!email){
    //         setCanPurchase(false);
    //         return;
    //     }
    
    //     fetch(`/api1/purchaseable/${email}`, {
    //       method: 'GET',
    //     }).then(async result => {
    //         const textResponse = await result.text();
    //         if(result.status == 200){
    //             if(textResponse === "Yes"){
    //                 setCanPurchase(true);
    //             }else{
    //                 setCanPurchase(false);
    //             }
    
    //         }else{
    //             // console.log("error: " + result);
    //             setCanPurchase(false)
    //         }
    //     });
    //   }, []);
}

export default function EditPost() {
  const {id} = useParams();
  const navigate = useNavigate();

  const [blogPost, setBlogPost] = useState({});
  const [isBlogPostLoaded, setIsBlogPostLoaded] = useState(false);
  const [file, setFile] = useState(null);
  const [markdownValue, setMarkdownValue] = useState('');
  const [postCategory, setPostCategory] = useState('');
  const [imgUploadAlert, setImgUploadAlert] = useState(false);
  const [imgStartUpload, setImgStartUpload] = useState(false);
  const [imgUploadAlertContent, setImgUploadAlertContent] = useState('');
  const [newImgURL, setNewImgURL] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    let unsubscribed = false;
    const fetchBlogPostById = async postId => {
      const res = await getBlogPostById(postId);
      if (!unsubscribed) {
        setBlogPost(res);
        setIsBlogPostLoaded(true);
        setMarkdownValue(res.Content);
        setPostCategory(res.PostCategory);
        setNewImgURL(res.ImageURL);
        setTitle(res.Title);
      }
    };
    fetchBlogPostById(id);
    return () => {
      unsubscribed = true;
    };
  }, [id]);

  const handleSelectChange = event => {
    setPostCategory(event.target.value);
  };

  const handleFileChange = e => {
    setFile(e.target.files[0]);
    // console.log(e.target.files[0]);
    // if (typeof e.target.files[0] != 'undefined') {
    //   const size = parseFloat(e.target.files[0].size / 1024).toFixed(2);
    //   if (size > 10000) {
    //     setAlertPhotoMessage("Photo/Video can't exceed 10MB");
    //     setIsPhotoError(true);
    //   } else {
    //     setIsPhotoError(false);
    //   }
    // } else {
    //   setAlertPhotoMessage('Wrong file type or no media uploaded');
    // }
  };

  const handleSubmitImg = async () => {
    // Upload new image
    setImgStartUpload(true);
    try {
      const res = await createPostImg(file);
      setImgStartUpload(false);
      if (res.HttpCode === 201) {
        setImgUploadAlert(true);
        setImgUploadAlertContent(
          `File uploaded successfully. Image URL is https://playdreamworld.b-cdn.net/Website/${file.name}.`
        );
        setNewImgURL(`https://playdreamworld.b-cdn.net/Website/${file.name}`);
      } else {
        setImgUploadAlert(true);
        setImgUploadAlertContent('Failed to upload file. Please try again.');
      }
    } catch (err) {
      alert(err);
    }

    // Delete old image
    try {
      await deletePostImg(blogPost.ImageURL.split('/').pop());
    } catch (err) {
      alert(err);
    }
  };

  const handleSubmitBlogPost = async e => {
    e.preventDefault();
    const postToSubmit = {
      UUID: blogPost.UUID,
      Content: markdownValue,
      Date: blogPost.Date,
      ImageURL: newImgURL,
      PostCategory: postCategory,
      Title: title,
    };

    try {
      const response = await editBlogPost(postToSubmit);
      if (response !== `Blog post ${id} updated successfully`) {
        alert(response);
        return;
      }
      navigate('/community');
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Box sx={{background: 'black'}}>
        <Header />
      </Box>
      {isBlogPostLoaded ? (
        <>
          <Box sx={{display: 'flex', alignItems: 'center', mb: 3, ml: 1, mt: 1}}>
            <Typography sx={{mr: 2}}>Title: </Typography>
            <TextField
              variant="standard"
              id="title"
              name="title"
              value={title}
              onChange={e => {
                setTitle(e.target.value);
              }}
              sx={{
                width: '300px',
              }}
            />
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', ml: 1}}>
            <Typography sx={{mr: 2}}>Replace Image: </Typography>
            <input
              data-test="selectFile"
              className="uploadButton"
              type="file"
              id="photoUpload"
              name="photoUpload"
              onChange={handleFileChange}
            />
            <button
              data-test="uploadButton"
              className="uploadImageButton"
              onClick={handleSubmitImg}
            >
              Upload
            </button>
            {imgStartUpload ? <CircularProgress size={25} sx={{ml: 2}} /> : null}
          </Box>
          {imgUploadAlert ? (
            imgUploadAlertContent === 'Failed to upload file' ? (
              <Alert severity="error">
                {imgUploadAlertContent} sx={{mt: 1}}
              </Alert>
            ) : (
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Alert severity="success" sx={{mr: 2, mt: 1}}>
                  {imgUploadAlertContent}
                </Alert>
                {window.isSecureContext && navigator.clipboard ? (
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(newImgURL);
                    }}
                  >
                    Copy URL
                  </button>
                ) : null}
              </Box>
            )
          ) : null}
          <Box sx={{mb: 3, ml: 1, mt: 2}}>
            <FormControl fullWidth>
              <InputLabel id="post-label" size="small" sx={{color: 'black', fontSize: '15px'}}>
                Post Category
              </InputLabel>
              <Select
                labelId="post-label"
                id="post-label"
                value={postCategory}
                defaultValue=""
                label="Post Category"
                onChange={handleSelectChange}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#767676',
                  },
                  width: 180,
                  height: 38,
                  backgroundColor: '#efefef',
                }}
              >
                <MenuItem value="Dev Post">Dev Post</MenuItem>
                <MenuItem value="Announcement">Announcement</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <MDEditor
            onChange={(newValue = '') => setMarkdownValue(newValue)}
            value={markdownValue}
            textareaProps={{
              placeholder: 'Please enter Markdown text',
            }}
            height={500}
          />
          <Box sx={{display: 'flex', justifyContent: 'center', mt: 5, mb: 5}}>
            <Button variant="contained" color="success" sx={{mr: 3}} onClick={handleSubmitBlogPost}>
              Post
            </Button>
            <Button variant="contained" color="error" onClick={() => navigate('/community')}>
              Cancel
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
