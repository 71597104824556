import {useNavigate} from 'react-router-dom';
import './PlayNowButton.css';

export default function PlayNowButton() {
  let navigate = useNavigate();

  return (
    <button
      className="new-btn new-btn-play-now btn-shine-anim"
      onClick={() => navigate('/purchase')}
    >
      PLAY NOW
    </button>
  );
}
