import React from "react";
import { Button } from "@mui/material";
import { showFounders } from "../../theme";

export const NewFooter = () => {
	const lineDivider = (
		<img
			className="line-divider"
			alt="Line divider"
			src="../assets/img/new_design/GraphicAssets/line-divider.png"
		/>
	);

	return (
		<div className="new-footer">
			{/*Logo */}

			<Button
				alt="Logo"
				className="logo"
				onClick={() => window.open("/", "_self")}
				sx={{
					top: "34px",
					"&.MuiButton-root:hover": { bgcolor: "transparent" },
				}}
			>
				<img
					alt="Logo"
					src="../assets/img/new_design/GraphicAssets/DWLogoColorSmall.png"
					height={82}
					width={82}
				></img>
			</Button>

			{/*Navbar*/}
			<div className="navbar">
				<a href="/">
					<div className="text-wrapper">HOME</div>
				</a>
				{lineDivider}
				<a href="/community">
					<div className="text-wrapper">UPDATES</div>
				</a>

				{lineDivider}
				<a href="/about">
					<div className="text-wrapper">ABOUT</div>
				</a>
				{lineDivider}
				<a href="/contact">
					<div className="text-wrapper">CONTACT</div>
				</a>
				{showFounders ? (
					<>
						{lineDivider}
						<a href="/purchase">
							<div className="text-wrapper">FOUNDERS</div>
						</a>
					</>
				) : null}
			</div>

			{/*Legal */}
			<div className="bot-footer-legal">
				<a href="/terms-of-use" target="_blank">
					<div className="text-wrapper2">Terms of Use</div>
				</a>
				{lineDivider}
				<a href="/privacy-policy" target="_blank">
					<div className="text-wrapper2">Privacy Policy</div>
				</a>
				{lineDivider}
				<a href="/cookie-policy" target="_blank">
					<div className="text-wrapper2">Cookie Policy</div>
				</a>

				{lineDivider}
				<a
					href="/content-standards-and-community-guidelines"
					target="_blank"
				>
					<div className="text-wrapper2">
						Content & Community Guidelines
					</div>
				</a>

				{lineDivider}
				<a
					href="https://www.ycombinator.com/companies/dreamworld/jobs"
					target="_blank"
				>
					<div className="text-wrapper2">Careers</div>
				</a>
			</div>

			<div className="bot-footer-copyright">
				<div className="text-wrapper3">© 2023 DREAMWORLD</div>
				<div className="text-wrapper3">All Rights Reserved.</div>
			</div>
		</div>
	);
};
