import React from "react";
import { StylizedTextBlock } from "../../components/contact-us/StylizedTextBlock";
import { CTAButton } from "../../components/widget/CTAButton";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";

export function CallingCreatorsBlock() {
	const text = (
		<>
			{" "}
			<Grid container spacing={2}>
				<Grid
					lg={6}
					md={12}
					sm={12}
					alignContent="center"
					justifyContent="center"
					display={"flex"}
				>
					<img
						src={
							process.env.PUBLIC_URL +
							"/assets/img/new_design/GraphicAssets/CraftingBench.png"
						}
						style={{
							maxHeight: "476px",
							maxWidth: "700px",
							aspectRatio: "58%",
						}}
						height={"auto"}
						width={"100%"}
					></img>
				</Grid>
				<Grid lg={6} md={12} sm={12}>
					<div className="stylized-text-block">
						<p className="text-wrapper">
							Attention content creators, streamers, YouTubers,
							and influencers! DreamWorld welcomes creators of all
							sizes to join our community, collaborate with our
							team, and be a part of unveiling the intricately
							crafted world we've built. If you're passionate
							about gaming, creating engaging content, and have a
							love for sandbox games and storytelling, we'd love
							to work with you.
						</p>
						<Typography
							sx={{
								fontSize: "32px",
								color: "rgba(255, 255, 255, 1)",
							}}
						>
							Creator Perks
						</Typography>
						<ul className="text-wrapper">
							<li>Exclusive access to invite-only game events</li>
							<li>Special creator items</li>
							<li>Promotion on social channels</li>
							<li>And more!</li>
						</ul>
					</div>
				</Grid>
			</Grid>
		</>
	);

	const button = (
		<CTAButton
			CTAButtonText="Sign Up"
			link="https://www.ycombinator.com/companies/dreamworld/jobs"
			newTab={true}
		></CTAButton>
	);

	return (
		<>
			<StylizedTextBlock
				title="Calling All Creators"
				text={text}
				CTAButton={button}
			></StylizedTextBlock>
			{/* {text} */}
		</>
	);
}
