import Cookies from 'js-cookie';

const createAccount = async data => {
  try {
    const response = await fetch('/api1/create_account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const textResponse = await response.text();
    return textResponse;
  } catch (error) {
    return error;
  }
};

const createAccount247 = async data => {
    try {
      const response = await fetch('/api1/create_account_alpha2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const textResponse = await response.text();
      return textResponse;
    } catch (error) {
      return error;
    }
  };

const login = async data => {
  try {
    const response = await fetch('/api1/authemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    return error;
  }
};

const canPurchase = async email => {
    try {
      const response = await fetch(`/api1/purchaseable/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
    });

      const textResponse = await response.text();
      return textResponse;
    } catch (error) {
      return error;
    }
  };
  

const getSessionByID = async sessionID => {
  try {
    const response = await fetch(`/api/session/${sessionID}`);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    return error;
  }
};

// Gets the stored session from storage and check to see if it is still valid
export const isSessionValid = () => {
    let session = Cookies.get("DWsession");
    return session != null;
}


const recoverPwd = async data => {
    try {
      const response = await fetch('/api1/recover_pwd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      return error;
    }
  };

const resetPwd = async data => {
    try {
        const response = await fetch('/api1/reset_pwd', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        return error;
    }
};


export {createAccount, createAccount247, login, getSessionByID, recoverPwd, resetPwd, canPurchase};
