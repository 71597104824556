import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { GenericPictureTitle } from "../../components/header/GenericPictureTitle";
import { CommunityGuidelinesTextBlock } from "./CommunityGuidelinesText";
import { LinksBlock } from "../../components/footer/LinksBlock";

export function CommunityGuidelinesPage() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>

			<GenericPictureTitle
				TitleName={"CONTENT STANDARDS AND COMMUNITY GUIDELINES"}
			></GenericPictureTitle>
			<CommunityGuidelinesTextBlock></CommunityGuidelinesTextBlock>
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
}
