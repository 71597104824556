const rootURL = 'https://ny.storage.bunnycdn.com/downloadstorage/Website/Blogs';

const createPostImg = async data => {
  const url = `${rootURL}/${data.name}`;
  const options = {
    method: 'PUT',
    headers: {
      'content-type': 'application/octet-stream',
      AccessKey: process.env.REACT_APP_BUNNY_API_KEY,
    },
    body: data,
  };
  try {
    const response = await fetch(url, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    return error;
  }
};

const deletePostImg = async name => {
  const url = `${rootURL}/${name}`;
  const options = {
    method: 'DELETE',
    headers: {
      AccessKey: process.env.REACT_APP_BUNNY_API_KEY,
    },
  };
  try {
    const response = await fetch(url, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    return error;
  }
};

export {createPostImg, deletePostImg};
