import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { switchLoginStatus } from "../../login/loginStatusSlice";
import { getSessionByEmail, recoverPwd, resetPwd } from "../../../api/Account";
import { CTAButton } from "../CTAButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { SignupCompleteBox } from "./SignupComplete";
import {
	accountWidgetActions,
	changeWindowType,
	incrementStageType,
} from "../../login/accountWidgetSlice";
import { AccountWidgetContext } from "./SignupWidgetMain";

import { useSearchParams } from "react-router-dom";

export const RecoveryWidget = ({ bForPurchase = false }) => {
	const dispatch = useDispatch();
	const accountWidgetStatus = useSelector((state) => state.accountWidget);

	const [loginAlertSeverity, setLoginAlertSeverity] = useState("error");

	const {
		showPwd,
		setShowPwd,
		loginEmail,
		setLoginEmail,
		loginEmailError,
		setLoginEmailError,
		loginPassword,
		setLoginPassword,
		loginPwdError,
		setLoginPwdError,
		loginAlertMessage,
		setLoginAlertMessage,
	} = useContext(AccountWidgetContext);

	// Get Query Params from the URL
	const [searchParams, setSearchParams] = useSearchParams();
	let email = searchParams.get("email");
	let resetToken = searchParams.get("password_reset_token");
	if (email != null) {
		setLoginEmail(email);
	}

	const validateEmail = (email) => {
		const emailRegex = new RegExp(
			"^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{1,31}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$"
		);

		setLoginEmail(email);

		if (!emailRegex.test(email)) {
			setLoginEmailError("Invalid email address");
			return false;
		}

		setLoginEmailError("");
		return true;
	};

	const validatePassword = (loginPassword) => {
		const passwordRegex = new RegExp(
			"^[a-zA-Z0-9.!@#$%&'*+/=?^_`{|}~-]{8,32}$"
		);

		setLoginPassword(loginPassword);
		if (!passwordRegex.test(loginPassword)) {
			setLoginPwdError(
				"Password requirements: 8-32 characters [digits, letters, or !@#$&* symbols]"
			);
			return false;
		}

		setLoginPwdError("");
		return true;
	};

	const validateEmailandPassword = () => {
		return validateEmail(loginEmail) && validatePassword(loginPassword);
	};

	const handleRecoverPassword = async (e) => {
		setLoginAlertSeverity("error");
		if (!validateEmail) {
			return;
		}

		const newAccount = { email: loginEmail };
		try {
			const response = await recoverPwd(newAccount);
			if (response.status === 200) {
				setLoginAlertMessage("Password reset email sent");
				setLoginAlertSeverity("success");
			} else if (response.status === 400) {
				setLoginAlertMessage("Invalid email");
			} else {
				// Error sending to server
				setLoginAlertMessage(
					"Error Resetting Password. Please try again later."
				);
			}
		} catch (err) {
			setLoginAlertMessage(err);
		}
	};

	const handleResetPassword = async (e) => {
		setLoginAlertSeverity("error");
		if (!validateEmailandPassword()) {
			return;
		}

		const account = {
			email: loginEmail,
			password: loginPassword,
			passwordResetToken: resetToken,
		};

		try {
			const response = await resetPwd(account);
			if (response.status === 200) {
				setLoginAlertMessage("Password has been reset");
				setLoginAlertSeverity("success");
			} else if (response.status === 400) {
				setLoginAlertMessage(
					"Reset link is expired or account is invalid"
				);
			} else {
				// Error sending to server
				setLoginAlertMessage(
					"Error resetting password. Please try again later."
				);
			}
		} catch (err) {
			setLoginAlertMessage(err);
		}
	};

	const handleButtonClick = async () => {
		if (accountWidgetStatus.value === "recover") {
			await handleRecoverPassword();
		} else {
			await handleResetPassword();
		}
	};

	var fieldBoxSX = {
		background: "#f6f4ec99",
		input: { color: "#4f4f52" },
		"& fieldset": { border: "none" },
		borderRadius: 2,
		height: 50,
		width: "83%",
	};

	const emailField = (
		<TextField
			id="login-email"
			label="Email Address"
			name="login-email"
			value={loginEmail}
			onChange={(e) => validateEmail(e.target.value)}
			autoFocus
			className="login-field"
			variant="filled"
			error={loginEmailError != ""}
			helperText={loginEmailError}
			sx={[
				fieldBoxSX,
				{
					"& input:-internal-autofill-selected": {
						// WebkitBoxShadow: '0 0 0 30px #15171d inset',
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "#ABABAB",
						height: 17,
						borderRadius: 5,
					},
				},
			]}
			InputLabelProps={{
				style: { color: "#4f4f52" },
			}}
		/>
	);

	var passwordField = (
		<TextField
			name="login-password"
			label="Password"
			type={showPwd ? "" : "password"}
			id="login-password"
			variant="filled"
			value={loginPassword}
			onChange={(e) => validatePassword(e.target.value)}
			error={loginPwdError != ""}
			helperText={loginPwdError}
			sx={fieldBoxSX}
			InputLabelProps={{
				style: { color: "#4f4f52" },
			}}
			hidden={accountWidgetStatus.value === "recover"}
		/>
	);

	const checkBox = () => {
		if (accountWidgetStatus.value === "reset") {
			return (
				<FormControlLabel
					className="check-box"
					sx={{ marginLeft: "60px" }}
					control={
						<Checkbox
							size="large"
							onChange={(e) => setShowPwd(e.target.checked)}
							sx={{
								color: "#ebdfb5",
								"&.Mui-checked": {
									color: "#ebdfb5",
								},
							}}
						/>
					}
					label="Show Password"
				/>
			);
		}
		return null;
	};

	const loginErrorBox =
		loginAlertMessage != "" ? (
			<Alert
				severity={loginAlertSeverity}
				sx={{
					width: "80%",
					ml: 4,
					mt: 2,
				}}
			>
				{loginAlertMessage}
			</Alert>
		) : null;

	const descriptionText = () => {
		if (accountWidgetStatus.value === "recover") {
			return (
				<>
					<p className="small-description">
						Enter the email address linked to your account, and
						we'll promptly send you a link to reset your password.
					</p>
					<p className="small-description">
						Please check your junk folder as well.
					</p>
				</>
			);
		} else {
			return (
				<p className="small-description" style={{ height: "70px" }}>
					Remember to choose a strong and secure password to keep your
					account safe.
				</p>
			);
		}
	};

	// Displays the login/account fields
	function loginFields() {
		let boxBottom = accountWidgetStatus.value === "reset" ? "0px" : "50px";

		return (
			<>
				{/* Alpha 2 Description */}
				<Box sx={{ marginTop: "20px", marginBottom: boxBottom }}>
					{descriptionText()}
				</Box>

				{/* Sign up box */}
				<Box className="login-form">
					{emailField}
					{passwordField}

					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
						}}
					>
						{checkBox()}
					</Box>
				</Box>

				{/* CTA BUTTON */}
				<CTAButton
					CTAButtonText={
						accountWidgetStatus.value === "recover"
							? "SUBMIT"
							: "RESET"
					}
					className="CTA-instance"
					link="https://discord.gg/7MUByhwEub"
					newTab={false}
					disabled={
						accountWidgetStatus.value === "reset"
							? loginEmailError != "" ||
							  loginPwdError != "" ||
							  loginEmail.length == 0 ||
							  loginPassword.length == 0
							: loginEmail.length == 0 || loginEmailError != ""
					}
					onClick={handleButtonClick}
				/>

				{/* Error message if server returned an error */}
				{loginErrorBox}
			</>
		);
	}

	return (
		<div className="dreamworld-sign-up">
			{/* Title Text */}
			<div className="title-text">
				<p className="title">
					{accountWidgetStatus.value === "recover"
						? "Forgot password?"
						: "Reset Password"}
				</p>
			</div>

			{accountWidgetStatus.stage == 0
				? loginFields()
				: SignupCompleteBox()}
		</div>
	);
};
