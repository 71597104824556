import React from "react";
import SignupWidgetMain from "../widget/SignupWidget/SignupWidgetMain";
import { Box } from "@mui/material";
export function LoginBlock() {
	return (
		<div className="landing-page">
			<video
				className="video"
				id="Mp4Video-165609712441beaecb"
				src="https://cdn.shopify.com/videos/c/o/v/1de5ca0eac044bc78c53327560a502d9.mp4"
				loop
				muted={true}
				playsInline
				autoPlay={true}
			></video>

			<Box
				sx={{
					background:
						"linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,0.8) 50%)",
					width: "100%",
					height: "200px",
					zIndex: 1,
					bottom: "0px",
					position: "absolute",
				}}
			></Box>

			<div className="overlap-group">
				{SignupWidgetMain("registration")}
			</div>
		</div>
	);
}
