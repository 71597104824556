import React from "react";
import { NewHeader } from "../../components/header/NewHeader";
import { NewFooter } from "../../components/footer/NewFooter";
import { LinksBlock } from "../../components/footer/LinksBlock";
import { FoundersLoginBlock } from "./FoundersLoginBlock";
import { FoundersIntroBlock } from "./FoundersIntroBlock";

export const FoundersPackPage = ({ PurchaseSuccess = false }) => {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>
			<div className="founders-pack">
				<FoundersLoginBlock
					PurchaseSuccess={PurchaseSuccess}
				></FoundersLoginBlock>
				<FoundersIntroBlock></FoundersIntroBlock>
			</div>
			<LinksBlock></LinksBlock>
			<NewFooter></NewFooter>
		</>
	);
};
