import {createSlice} from '@reduxjs/toolkit';

// The slice is registered within the store.js file
const initialState = {value: 'registration', stage: 0};

const accountWidgetSlice = createSlice({
  name: 'accountWidget',
  initialState,
  reducers: {
    accountWidgetActions(state = {}, action = {}) {
        // console.log(action)

        switch(action.payload.type){
            case INCREMENT_STAGE:
                state.stage = state.stage + 1;
                break;
            case CHANGE_WINDOW_TYPE:
                state.value = action.payload.value;
                state.stage = 0;
                break;
            case RESET_STAGE:
                state.stage = 0;
                break;
            case CHANGE_STAGE:
                state.stage = action.payload.value;
                break;
            return state;
        }
    },
  },
});

export const {accountWidgetActions} = accountWidgetSlice.actions;

export default accountWidgetSlice.reducer;


// Actions
const INCREMENT_STAGE = 'INCREMENT_STAGE'
export const incrementStageType = () =>{
    return{
        type: INCREMENT_STAGE,
    }
}


const CHANGE_WINDOW_TYPE = 'CHANGE_WINDOW_TYPE'
export const changeWindowType = (value) =>{
    return{
        type: CHANGE_WINDOW_TYPE,
        value,
    }
}

const RESET_STAGE = 'RESET_STAGE'
export const resetStageType = (value) =>{
    return{
        type: RESET_STAGE,
        value,
    }
}

const CHANGE_STAGE = 'CHANGE_STAGE'
export const changeStageType = (value) =>{
    return{
        type: CHANGE_STAGE,
        value,
    }
}