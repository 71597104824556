import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NewLoginWidget } from "./NewLoginWidget";
import {
	accountWidgetActions,
	changeWindowType,
	incrementStageType,
} from "../../login/accountWidgetSlice";
import { switchLoginStatus } from "../../login/loginStatusSlice";
import { NewPurchaseWidget } from "./NewPurchaseWidget";
import { isSessionValid } from "../../../api/Account";
import { createContext } from "react";
import { RecoveryWidget } from "./RecoveryWidget";

export const AccountWidgetContext = createContext("");
export const useAccountWidgetContext = () => useContext(AccountWidgetContext);

export const AccountWidgetProvider = (props) => {
	// Login Hooks (Hooks need to be kept at the top most component)
	const [showPwd, setShowPwd] = useState(false);
	const [loginEmail, setLoginEmail] = useState("");
	const [loginEmailError, setLoginEmailError] = useState("");
	const [loginPassword, setLoginPassword] = useState("");
	const [loginPwdError, setLoginPwdError] = useState("");
	const [loginAlertMessage, setLoginAlertMessage] = useState("");
	const [promoCode, setPromoCode] = useState("");

	const value = {
		showPwd,
		setShowPwd,
		loginEmail,
		setLoginEmail,
		loginEmailError,
		setLoginEmailError,
		loginPassword,
		setLoginPassword,
		loginPwdError,
		setLoginPwdError,
		loginAlertMessage,
		setLoginAlertMessage,
		promoCode,
		setPromoCode,
	};

	return (
		<AccountWidgetContext.Provider value={value}>
			{props.children}
		</AccountWidgetContext.Provider>
	);
};

export default function SignupWidgetMain(default_mode = "registration") {
	const dispatch = useDispatch();
	const accountWidgetStatus = useSelector((state) => state.accountWidget);

	const [isFirstLoad, setIsFirstLoad] = useState(true);

	if (isFirstLoad) {
		var startMode = default_mode;
		setIsFirstLoad(false);

		// This is a special redirect
		if (default_mode === "purchase_success") {
			dispatch(accountWidgetActions(changeWindowType("purchase")));
			dispatch(accountWidgetActions(incrementStageType()));
		} else {
			if (default_mode === "purchase" && !isSessionValid()) {
				startMode = "registration";
			}

			dispatch(accountWidgetActions(changeWindowType(startMode)));
		}
	}

	function widgetSwitch(accountWidgetStatus) {
		switch (accountWidgetStatus.value) {
			case "registration":
				return (
					<NewLoginWidget
						bForPurchase={default_mode === "purchase"}
					></NewLoginWidget>
				);
			case "login":
				return (
					<NewLoginWidget
						bForPurchase={default_mode === "purchase"}
					></NewLoginWidget>
				);
			case "purchase":
				return (
					<NewPurchaseWidget
						stage={accountWidgetStatus.stage}
					></NewPurchaseWidget>
				);
			case "recover":
				return <RecoveryWidget></RecoveryWidget>;
			case "reset":
				return <RecoveryWidget></RecoveryWidget>;
			default:
				return <NewLoginWidget bForPurchase={false}></NewLoginWidget>;
		}
	}

	return (
		<div className="login-widget">
			<AccountWidgetProvider>
				{widgetSwitch(accountWidgetStatus)}
			</AccountWidgetProvider>
		</div>
	);
}
