import React from "react";
import { Box } from "@mui/material";
import { CTAButton } from "../widget/CTAButton";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export const SignupBlock = () => {
	const theme = useTheme();
	const medMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	const mobileStyle = {
		position: "relative",
		justifySelf: "end",
		bottom: "-35%",
	};
	const desktopStyle = {
		position: "relative",
		justifySelf: "end",
		bottom: "-35%",
	};

	const onButtonPress = () => {
		// window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<div className="be-the-first-to-play">
			<Box sx={medMatchDownMd ? mobileStyle : desktopStyle}>
				{/* <div className="discord-info"> */}
				{/* <div className="section-header">
						<img
							className="header-style-graphic-right"
							src="assets/img/new_design/GraphicAssets/Header-style-graphic-left.png"
						></img>
						<div className="SectionHeaderTitle">
							Be The First To Play!
						</div>
						<img
							className="header-style-graphic-left"
							src="assets/img/new_design/GraphicAssets/Header-style-graphic-right.png"
						></img>
					</div> */}

				{/* <div className="CTA-group"> */}
				{/* <p className="small-text1">
							Sign up to claim your spot for the Alpha 2 release!
						</p> */}

				<CTAButton
					CTAButtonText="JOIN NOW"
					className="CTA-instance"
					// onClick={onButtonPress}
					sx={{
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
					}}
					link="https://store.steampowered.com/app/3103280/DreamWorld_The_Infinite_Sandbox_MMO/"
					newTab={true}
				/>
				{/* </div> */}
				{/* </div> */}
			</Box>
		</div>
	);
};
