
import './index.css';
import { createTheme } from '@mui/material';

export const showFounders = false;
export const websiteSecondaryColor = "#3f3a2c"

// These should match responsive.css
export const theme = createTheme({
    typography: {
      fontFamily: ['Mulish', 'sans-serif'].join(','),
    },
  
    breakpoints: {
      values: {
        sm: 481,
        md: 780,
        lg: 1200,
        xl: 1920,
      },
    },
  });
  
