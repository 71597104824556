import PropTypes from "prop-types";
import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const MainSectionHeader = ({
	bodyText = "Insert text here",
	mainSectionText = "Main Section Text",
	left = true,
}) => {
	var graphic = left
		? "../assets/img/new_design/GraphicAssets/Main-Section-Graphic-Left.png"
		: "../assets/img/new_design/GraphicAssets/Main-Section-Graphic-Right.png";

	var headerClass = left
		? "main-section-header-left"
		: "main-section-header-right";

	var className = `main-section-header ` + headerClass;

	const theme = useTheme();
	const medMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<div className={className}>
			<div className="main-section-text">{mainSectionText}</div>
			{medMatchDownMd ? null : (
				<img
					className="main-section-graphic"
					alt="Main section graphic"
					src={graphic}
				/>
			)}

			<div className="insert-text-here">{bodyText}</div>
		</div>
	);
};

MainSectionHeader.propTypes = {
	bodyText: PropTypes.string,
	mainSectionText: PropTypes.string,
	left: PropTypes.bool,
};
